import React from 'react';
import './ValidationError.css';

/**
 * Displays an inline error message when an invalid component is hovered.
 *
 * Must be an immediate sibling of the component in question.
 *
 * Usage:
 * ```js
 * <ValidationError>My error message</ValidationError>
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const ValidationError: React.FunctionComponent = (props) => {
    const markup = (
        <div className='error-validation rounded'>
            {props.children}
        </div>
    );

    return props.children ? markup : null;
};
