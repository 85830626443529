import React, { Fragment, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { dmrSponsorChanged, debouncedFetchSponsors } from '../store/editor/editorSlice';
import { SponsorData, IabData } from '../interfaces/Sponsor';
import Labels from '../assets/labels.json';
import { ValidationError } from './ValidationError';
import { validator } from '../utils/validator';
import { OptionsType } from 'react-select';
import { RootState } from '../store/reducer';
import './Sponsor.css';


const getOptionLabel = (option: SponsorData) => option.name;

const getOptionValue = (option: SponsorData) => {
    return `${option.id}`;
};

interface SponsorProps {
    readonly?: boolean;
    sponsor?: SponsorData[];
    onFocus: () => void;
}

// tslint:disable-next-line: variable-name - React function components must be capitalized
export const Sponsor: React.FunctionComponent<SponsorProps> = (props) => {

    const sessionId = useSelector((state: RootState) => state.session.sessionId);

    let errorMessage = '';
    const dispatch = useDispatch();

    const isSponsorValid = (sponsor: SponsorData[]) => {
        return sponsor.length > 0 && sponsor.every((sponsorEntity) => validator.isSponsorValid(sponsorEntity));
    };

    const [inputValue, setInputValue] = useState('');
    const [isPristine, setIsPristine] = useState(true);
    const [isValid, setIsValid] = useState(isSponsorValid(props.sponsor));
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const shouldDisplayErrors = !isPristine && !isValid;

    if (shouldDisplayErrors) {
        errorMessage = Labels.sponsorSelectionValidationMsg;
    }

    const loadOptions = (input: string, callback: (options: OptionsType<SponsorData>) => void) => {
        if (input.length > 0) {
            debouncedFetchSponsors(input, sessionId, callback);
        }
    };

    const renderClassNames = (): string => {
        const classNames = ['sponsor-autocomplete'];

        if (menuIsOpen) {
            // the Select widget is unusual: when the menu of options is opened, the original autocomplete element loses focus;
            // adding this class allows us to write styles to effect the display of validation messages via the ValidationError component
            classNames.push('focused');
        }

        if (isPristine) {
            classNames.push('pristine');
        }

        if (!isValid) {
            classNames.push('invalid');
        }

        return classNames.join(' ');
    };
    const blurHandler = () => {
        // We want to update 'pristine' and 'valid' only when the user blurs the
        // input; otherwise, they would get an error message while typing.
        setIsPristine(false);
        setIsValid(isSponsorValid(props.sponsor));
    };

    // We are using a 'controlled input' approach here because the underlying functionality of the Select component clears
    // the input value when the user has started typing and blurs without having made a selection.
    const inputChangeHandler = (inputValue: string, data: { action: 'set-value' | 'input-change' | 'input-blur' | 'menu-close' }) => {
        if (data.action === 'input-change' || data.action === 'set-value') {
            setInputValue(inputValue);
        }
    };

    const selectChangeHandler = (selection: SponsorData[]) => {
        props.onFocus();
        setIsPristine(false);

        setIsValid(isSponsorValid(selection)); 
        dispatch(dmrSponsorChanged(selection)); 
    };

    const isDisabled = typeof props.readonly === 'undefined' ? false : props.readonly;
    return (
        <Fragment>
                <AsyncSelect
                    backspaceRemovesValue={true}
                    className={renderClassNames()}
                    classNamePrefix='react-select'
                    // See https://react-select.com/components#replaceable-components. We're replacing the subcomponent with nothing because
                    // our implementation has no preset options; clicking the indicator invariably displays "No options."
                    components={{ DropdownIndicator: () => null }}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    inputValue={inputValue}
                    isClearable={false}
                    onBlur={blurHandler}
                    onChange={selectChangeHandler}
                    onInputChange={inputChangeHandler}
                    onMenuOpen={() => { setMenuIsOpen(true); }}
                    onMenuClose={() => { setMenuIsOpen(false); }}
                    noOptionsMessage={() => null}
                    loadOptions={loadOptions}
                    isDisabled={isDisabled}
                    isMulti={true}
                    placeholder={Labels.sponsorSelectionPlaceholder}
                    value={props.sponsor}
                />
            <ValidationError>
                {errorMessage}
            </ValidationError>
        </Fragment>
    );
};
