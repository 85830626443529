import './AppMain.css';
import React, { useEffect, Fragment } from 'react';
import { MainHeader } from './MainHeader';
import { EventDetail } from './EventDetail';
import { DmrEvents } from './DmrEvents';
import Labels from '../assets/labels.json';
import { useSelector, useDispatch } from 'react-redux';
import LoadingWave from './LoadingWave';
import { AudioInputModal } from './AudioInputModal';
import { DeleteEventModal } from './DeleteEventModal';
import { RootState } from '../store/reducer';
import { Analytics } from '../api/analytics';
import { createOrExtendSession, isApiSuccess } from '../api/atmServer';
import { setSessionId } from '../store/session/actions';
import { EditWaveform } from './EditWaveform';
import { PreviewWaveform } from './PreviewWaveform';
import { SummaryModal } from './SummaryModal';
import { AppHeader } from './AppHeader';
import { useOktaAuth } from '@okta/okta-react';
import { EditorStageStates, fetchServerConfigs } from '../store/editor/editorSlice';
import { fetchSessionId } from '../store/config/configSlice';
import { DateDisagreementModal } from './DateDisagreementModal';

/**
 * Renders the entire application except the header.
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const AppMain: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const appConfig = useSelector((state: RootState) => state.config);
    const audioIsLoading = useSelector((state: RootState) => state.audio.isFetching);
    const audioIsPublishing = useSelector((state: RootState) => state.editor.stageState === EditorStageStates.publishing);
    const sessionId = useSelector((state: RootState) => state.session.sessionId);
    const { authState: { accessToken, isAuthenticated } } = useOktaAuth();

    useEffect(
        () => {
            if (isAuthenticated && !sessionId) {
                dispatch(fetchSessionId(accessToken.accessToken));
            }
        },
        [isAuthenticated, sessionId]
    );

    useEffect(
        () => {
            if (sessionId) {
                dispatch(fetchServerConfigs(sessionId));
            }
        },
        [sessionId]
    );

    useEffect(
        () => {
            if (!appConfig || !isAuthenticated) {
                return;
            }

            Analytics.init({
                auth: appConfig.analyticsAuth,
                containerId: appConfig.analyticsContainerId,
                env: appConfig.analyticsEnv,
            });

            const pingIntervalId = setInterval(
                async () => {
                    const apiResult = await createOrExtendSession(sessionId);
                    if (isApiSuccess(apiResult)) {
                        dispatch(setSessionId(apiResult.payload.data));
                    } else {
                        // TODO: display bad network error?
                    }
                },
                appConfig.keepaliveMs
            );

            // A function returned by useEffect is run only during the component cleanup.
            // This is comparable to componentWillUnmount.
            return () => {
                clearTimeout(pingIntervalId);
            };
        },
        [appConfig]
    );


    const render = (): JSX.Element => {
        if (audioIsLoading || !appConfig) {
            return (
                <LoadingWave loadingMessage={Labels.loadingMetadata} />
            );
        } else if (audioIsPublishing) {
            return (
                <LoadingWave loadingMessage={Labels.publishingAudio} />
            );
        }

        return (
            <Fragment>
                <AppHeader />
                <main className='app-main'>
                    <AudioInputModal />
                    <DeleteEventModal />
                    <SummaryModal />
                    <DateDisagreementModal />
                    <MainHeader />
                    <EditWaveform id='waveform-edit' />
                    <PreviewWaveform id='waveform-preview-dmr' rel='dmr' />
                    <div className='events-wrapper'>
                        <DmrEvents />
                        <EventDetail />
                    </div>
                </main>
            </Fragment>
        );
    };

    return render();
};
