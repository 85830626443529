import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SecurityWrapper } from './components/SecurityWrapper';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './store/index';

// tslint:disable-next-line: variable-name - React function components must be capitalized
export const App: React.FunctionComponent = () => (
    <Provider store={store}>
        <BrowserRouter>
            <SecurityWrapper />
        </BrowserRouter>
    </Provider>
);

