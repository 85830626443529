import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Labels from '../../assets/labels.json';
import { AppThunk } from '..';
import { fetchAudio, PodcastConfig, FetchAudioResponse, isApiSuccess } from '../../api/atmServer';
import { timelineEventsFetched } from '../editor/editorSlice';
import { setSessionId } from '../session/actions.js';
import { validator } from '../../utils/validator';
import { getExtension, seamusifyUri } from '../../utils/uri';

export interface AudioState {
    cdnPath: string;
    duration: number;
    isFetching: boolean;
    fileExt: string;
    fileSize: number;
    fileName: string;
    preflightErrorCode: keyof typeof Labels.loadSourceAudio.error;
    podcastConfig: PodcastConfig;
    scaledAmplitudes: number[];
    /**
     * An absolute path (sans origin and file extension) to the audio (e.g., /path/to/file).
     */
    seamusUri: string;
    sessionId: string;
}

const initialState: AudioState = {
    cdnPath: '',
    duration: 0,
    isFetching: false,
    fileExt: '',
    fileSize: 0,
    fileName: '',
    seamusUri: '',
    preflightErrorCode: null,
    podcastConfig: {
        podcastIconUrl: '',
        podcastId: 0,
        podcastName: '',
    },
    sessionId: '',
    scaledAmplitudes: []
};

const audioSlice = createSlice({
    initialState,
    name: 'audio',
    reducers: {
        audioFetchFailed(state, action: PayloadAction<keyof typeof Labels.loadSourceAudio.error>) {
            state.isFetching = false;
            state.preflightErrorCode = action.payload;
        },
        audioFetchStarted(state) {
            state.isFetching = true;
        },
        audioFetchSucceeded(state, action: PayloadAction<FetchAudioResponse>) {
            const { config, metadata, sessionId, scaledAmplitudes } = action.payload;

            state.cdnPath = metadata.cdnPath;
            state.duration = metadata.duration;
            state.isFetching = false;
            state.fileName = metadata.fileName;
            state.fileSize = metadata.fileSize;
            state.podcastConfig = config;
            state.preflightErrorCode = null;
            state.sessionId = sessionId;
            state.scaledAmplitudes = scaledAmplitudes;
        },
        fileUriUpdated(state, action: PayloadAction<{ podcastDirectories: string[], uri: string }>) {
            const { podcastDirectories, uri } = action.payload;
            const trimmedUri = uri.trim();
            // defaults to mp3 because (1) the extension isn't required, and (2) only mp3 are currently supported
            state.fileExt = getExtension(trimmedUri) || '.mp3';
            state.seamusUri = seamusifyUri(trimmedUri, podcastDirectories);
        },
        resetRequested() {
            return initialState;
        },
    },
});

export const {
    audioFetchFailed,
    audioFetchStarted,
    audioFetchSucceeded,
    fileUriUpdated,
    resetRequested,
} = audioSlice.actions;

export const audioReducer = audioSlice.reducer;

// begin thunks
export const fetchAudioThunk = (): AppThunk => async (dispatch, getState) => {
    dispatch(audioFetchStarted());

    const state = getState();
    const uri = `${state.audio.seamusUri}${state.audio.fileExt}`;
    const result = await fetchAudio(uri, state.session.sessionId);
    if (isApiSuccess(result)) {
        const { payload } = result;
        validator.setContext({
            adProducts: state.editor.adProductsList,
            audioDurationInSeconds: payload.metadata.duration,
        });

        dispatch(setSessionId(payload.sessionId));
        dispatch(audioFetchSucceeded(payload));
        dispatch(timelineEventsFetched(payload));
    } else {
        dispatch(audioFetchFailed(result.payload));
    }
};
