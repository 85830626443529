import { TimelineEvent, isDmr, isDmrInterval, DmrIntervalEvent } from '../interfaces/TimelineEvent';
import { AdProduct } from '../api/atmServer';
import utils from './utils';
import { SponsorData } from '../interfaces/Sponsor';

const invalidCharsRegex = /[",`\\]/;

/**
 * Validates events in an interval relative to one another.
 *
 * Does not re-validate the events individually; assumes the isValid flags are current.
 */
export function isIntervalValid(events: DmrIntervalEvent[]): boolean {
    // bail out early if basic conditions aren't met
    if (events.length !== 2) {
        return false;
    }

    const eventsAreInSameInterval = events[0].intervalKey === events[1].intervalKey;
    const eventsAreValid = events.every(event => event.isValid);

    const firstInPair = events.find(event => event.isFirstInInterval);
    const secondInPair = events.find(event => !event.isFirstInInterval);
    const eventsAreOrdered = !!firstInPair && !!secondInPair;

    return eventsAreInSameInterval
        && eventsAreValid
        && eventsAreOrdered
        && utils.convertEventTimeToSeconds(firstInPair.eventTime) < utils.convertEventTimeToSeconds(secondInPair.eventTime);
}

export function isLabelValid(label: string): boolean {
    return !invalidCharsRegex.test(label);
}

export function isTimecodeFormatValid(timecode: string): boolean {
    return /^\d{2}:([0-5][0-9]):([0-5][0-9])\.\d{3}$/.test(timecode);
}

class Validator {

    private adProducts: AdProduct[];
    private audioDurationInSeconds: number;
    private contextIsSet = false;

    /**
     * @throws error if called before context was passed to validator
     */
    public isBreakTypeValid(breakTypeId: number): boolean {
        this.verifyContextIsSet();
        return !!this.adProducts.find(product => product.isActive && product.id === breakTypeId);
    }

    /**
     * @throws error if called before context was passed to validator
     */
    public isSponsorValid(sponsor: SponsorData): boolean {
        this.verifyContextIsSet();
        return !!sponsor && !!sponsor.id && !!sponsor.iabData.length;
    }

    /**
     * @throws error if called before context was passed to validator
     */
    public isTimecodeInRange(timecode: string): boolean {
        this.verifyContextIsSet();
        const time = utils.convertEventTimeToSeconds(timecode);
        return time <= this.audioDurationInSeconds;
    }

    /**
     * Performs all validation checks on a TimelineEvent.
     *
     * @throws error if called before context was passed to validator
     */
    public isTimelineEventValid(event: TimelineEvent): boolean {
        this.verifyContextIsSet();

        let isValidDmr = isDmr(event) && this.isBreakTypeValid(event.type);

        if (isValidDmr && isDmrInterval(event)) {
            //In the event the array is length 0, the every() would return true, counter to the intent of the empty array which signifies having no sponsors
            isValidDmr = event.sponsor.length > 0 && event.sponsor.every((sponsorEntity) => this.isSponsorValid(sponsorEntity))
        }

        return (
            isTimecodeFormatValid(event.eventTime)
            && this.isTimecodeInRange(event.eventTime)
            && isValidDmr
        );
    }

    /**
     * Provides context for validations that cannot occur in a vacuum (e.g., is timecode within the bounds of the audio file).
     *
     * This must be supplied to the validator rather than retrieved directly from the state in order to avoid circular dependencies, e.g.,
     * when validating in a thunk.
     *
     * @param adProducts Valid selections for the ad product/break type.
     */
    public setContext(context: {
        adProducts: AdProduct[],
        audioDurationInSeconds: number,
    }) {
        this.adProducts = context.adProducts;
        this.audioDurationInSeconds = context.audioDurationInSeconds;
        this.contextIsSet = true;
    }

    /**
     * @throws error if context hasn't been set
     *
     * Users have no control over whether or not context is set. This helper function should help catch developer errors.
     */
    private verifyContextIsSet(): void {
        if (!this.contextIsSet) {
            throw new Error('Validator context must be set prior to using instance methods.');
        }
    }

}

export const validator = new Validator();
