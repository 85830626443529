import React, { Fragment, useState } from 'react';
import Labels from '../assets/labels.json';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import './DmrEvent.css';
import { RootState } from '../store/reducer';
import { dmrTypeChanged } from '../store/editor/editorSlice';
import { ValidationError } from './ValidationError';
import { validator } from '../utils/validator';

/**
 * Displays the type selector for a Dynamic Midroll event or event pair.
 *
 * Usage:
 * ```js
 * <BreakType typeId={numericId} />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const BreakType: React.FunctionComponent<{ typeId: number, readonly?: boolean }> = (props) => {
    interface ProductOption {
        label: string;
        value: number;
    }

    const adProducts = useSelector((state: RootState) => state.editor.adProductsList);
    const breakTypes: ProductOption[] = adProducts
        .filter(product => product.isActive)
        .map(product => ({
            label: product.name,
            value: product.id,
        }))
        .sort((a, b) => a.label < b.label ? -1 : 1);

    const dispatch = useDispatch();
    let err: string;

    const initialValue = breakTypes.find(type => type.value === props.typeId);

    const isBreakTypeValid = (breakTypeId: number) => {
        return validator.isBreakTypeValid(breakTypeId);
    };

    // no it's not just you; this is confusing: useState provides *local* component state, unrelated to our appstate slices
    const [isPristine, setIsPristine] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const shouldDisplayErrors = !isPristine && !isValid;
    if (shouldDisplayErrors) {
        err = Labels.breakSelectionValidationMsg;
    }

    const renderClassNames = (): string => {
        const classNames = ['break-type-autocomplete'];

        if (menuIsOpen) {
            // the Select widget is unusual: when the menu of options is opened, the original autocomplete element loses focus;
            // adding this class allows us to write styles to effect the display of validation messages via the ValidationError component
            classNames.push('focused');
        }

        if (isPristine) {
            classNames.push('pristine');
        }

        if (!isValid) {
            classNames.push('invalid');
        }

        if (props.readonly) {
            classNames.push('readonly');
        }

        return classNames.join(' ');
    };

    const updateBreakType = (selection: ProductOption | ProductOption[] | null) => {
        const breakTypeId = Array.isArray(selection) ? selection[0].value : selection.value;
        setIsPristine(false);
        setIsValid(isBreakTypeValid(breakTypeId));
        dispatch(dmrTypeChanged(breakTypeId));
    };

    return (
        <Fragment>
            <Select
                className={renderClassNames()}
                classNamePrefix='react-select'
                onChange={updateBreakType}
                onMenuClose={() => { setMenuIsOpen(false); }}
                onMenuOpen={() => { setMenuIsOpen(true); }}
                options={breakTypes}
                placeholder={Labels.breakSelectionPlaceholder}
                value={initialValue}
                isDisabled={!!props.readonly}
            />
            <ValidationError>{err}</ValidationError>
        </Fragment>
    );
};
