import React from 'react';

import Labels from '../assets/labels.json';
import { DmrEvent } from './DmrEvent';
import { DmrIntervalEvent, DmrSingleEvent, isDmrSingle, isDmrInterval } from '../interfaces/TimelineEvent';
import { RootState } from '../store/reducer';
import { useSelector } from 'react-redux';

/**
 * Renders the DMR events table.
 *
 * Usage:
 * ```js
 * <DmrEventsTable readonly />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const DmrEventsTable: React.FunctionComponent<{ readonly?: boolean }> = (props) => {
    const timelineEvents = useSelector(
        (state: RootState) => state.editor.timelineEvents.filter(e => (isDmrInterval(e) && e.isFirstInInterval) || isDmrSingle(e))
    ) as (DmrIntervalEvent | DmrSingleEvent)[];

    const renderedEvents = timelineEvents.map((event) => {
        return (
            <DmrEvent key={event.id} event={event} readonly={!!props.readonly} />
        );
    });

    return (
        <table className='container-dmr'>
            <thead>
                <tr>
                    <th className='time-start w-15'>{Labels.startEventTime}</th>
                    <th className='break-type w-30'>{Labels.breakSelectionHeader}</th>
                    <th className='time-end w-15'>{Labels.endEventTime}</th>
                    <th className='sponsor w-30'>{Labels.sponsor}</th>
                    <th className='actions w-10'></th>
                </tr>
            </thead>
            <tbody>{renderedEvents}</tbody>
        </table>
    );
};
