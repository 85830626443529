
import {
    SESSION_ID,
} from './types';


export const setSessionId = sessionId => ({
    type: SESSION_ID,
    sessionId,
});
