import React from 'react';
import Labels from '../assets/labels.json';
import { useDispatch, useSelector } from 'react-redux';
import './DmrEvent.css';
import { DmrIntervalEvent, isDmrInterval, TimelineEvent } from '../interfaces/TimelineEvent';
import { RootState } from '../store/reducer';
import { timelineEventFocused, deletionProposed } from '../store/editor/editorSlice';
import trash from '../assets/trash.svg';
import SVG from 'react-inlinesvg';
import { BreakType } from './BreakType';
import { Timecode } from './Timecode';
import { Sponsor } from './Sponsor';

interface DmrEventProps {
    event: TimelineEvent;
    readonly?: boolean;
}

/**
 * Displays a Dynamic Midroll event or event pair.
 *
 * Usage:
 * ```js
 * <DmrEvent event={event} />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const DmrEvent: React.FunctionComponent<DmrEventProps> = (props) => {
    const intervalKey = isDmrInterval(props.event) ? props.event.intervalKey : null;
    const pairEvent = useSelector((state: RootState) => state.editor.timelineEvents.find((e) => {
        return isDmrInterval(e) && e.intervalKey === intervalKey && e.id !== props.event.id;
    })) as DmrIntervalEvent | undefined;
    const secondEventTime = pairEvent ? pairEvent.eventTime : undefined;

    const dispatch = useDispatch();

    const isActive = useSelector((state: RootState) => state.editor.activeEventId === props.event.id);

    const makeEventActive = (): void => {
        if (props.readonly) {
            return;
        }
        dispatch(timelineEventFocused(props.event));
    };

    const proposeDeletion = () => {
        dispatch(deletionProposed(props.event.id));
    };

    const renderClassNames = (): string => {
        const classNames = ['event-dmr'];
        if (isActive) {
            classNames.push('selected');
        }
        if (props.readonly) {
            classNames.push('readonly');
        }

        return classNames.join(' ');
    };

    return (
        <tr className={renderClassNames()} onFocus={makeEventActive}>
            <td className='time-start'>
                <Timecode eventId={props.event.id} value={props.event.eventTime} ceiling={secondEventTime} readonly={props.readonly} />
            </td>
            <td className='break-type'>
                <BreakType typeId={props.event.type} readonly={props.readonly}/>
            </td>
            <td className='time-end'>
                {pairEvent ? <Timecode eventId={pairEvent.id} value={pairEvent.eventTime} floor={props.event.eventTime} />
                    : Labels.notApplicable}
            </td>
            <td className='sponsor'>
                { isDmrInterval(props.event)
                ? <Sponsor sponsor={props.event.sponsor} readonly={props.readonly} onFocus={makeEventActive}/>
                : Labels.notApplicable }
            </td>
            <td className='actions'>
            <span className='icon-wrapper' onClick={proposeDeletion}>
                <SVG className='icon-delete' src={trash} title={Labels.deleteButton} />
            </span>
            </td>
        </tr>
    );
};
