import React, { useContext } from 'react';
import { waveformContext } from './WaveformContext';

/**
 * Displays the button for transitioning to edit mode.
 *
 * Usage:
 * ```js
 * <Clock />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const Clock: React.FunctionComponent = () => {
    const waveformApi = useContext(waveformContext);

    const audioDuration = waveformApi.getDuration();
    const playheadPosition = waveformApi.getPlayheadPosition();

    return (
        <span className='clock'>{playheadPosition} / {audioDuration}</span>
    );
};
