import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Labels from '../assets/labels.json';
import { RootState } from '../store/reducer.js';
import { deletionCanceled, deletionConfirmed } from '../store/editor/editorSlice';

// tslint:disable-next-line: variable-name - React function components must be capitalized
export const DeleteEventModal: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const activeEventIsProposedForDeletion = useSelector((state: RootState) => state.editor.activeEventIsProposedForDeletion);

    // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#root');

    const cancel = () => {
        dispatch(deletionCanceled());
    };

    const confirm = () => {
        dispatch(deletionConfirmed());
    };

    const isModalOpen = () => {
        return activeEventIsProposedForDeletion;
    };

    return (
        <Modal
            isOpen={isModalOpen()}
            className='modal-centered modal-event-delete'
            overlayClassName='overlay'
        >
            <h2>{Labels.deleteConfirmationHeading}</h2>
            <p>{Labels.deleteConfirmationHelp}</p>
            <div className='button-group'>
                <button className='btn-medium secondary delete-cancel' onClick={cancel}>{Labels.deleteCancelButton}</button>
                <button className='btn-medium primary delete-confirm ml-10' onClick={confirm}>{Labels.deleteConfirmationButton}</button>
            </div>
        </Modal>
    );
};
