import React from 'react';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Labels from '../assets/labels.json';
import LoadingWave from './LoadingWave';
import { AppMain } from './AppMain';
import { fetchClientConfig } from '../store/config/configSlice';
import { RootState } from '../store/reducer';
import { GlobalMessage } from './GlobalMessage';

// tslint:disable-next-line: variable-name - React function components must be capitalized
export const SecurityWrapper: React.FunctionComponent = () => {
    let oktaAuth: OktaAuth;

    const history = useHistory();
    const dispatch = useDispatch();
    const appConfig = useSelector((state: RootState) => state.config);
    const restoreOriginalUri = async (oktaAuth: OktaAuth, orignalUri: string) => {
        history.replace(toRelativeUrl(orignalUri || '/', window.location.origin), { replace: true });
    }

    if (!appConfig) {
        dispatch(fetchClientConfig());
    }

    if (appConfig && !oktaAuth) {
        oktaAuth = new OktaAuth({
            clientId: appConfig.oktaClientId,
            issuer: appConfig.oktaOrgUrl,
            redirectUri: `${window.location.origin}/implicit/callback`,
        });
    }

    const render = (): JSX.Element => {
        return !appConfig
            ? <LoadingWave loadingMessage={Labels.loadingMetadata} />
            : <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <div>
                    <GlobalMessage />
                    <SecureRoute path='/' component={AppMain} />
                    <Route path='/implicit/callback' component={LoginCallback} />
                </div>
            </Security>;
    };

    return render();
};
