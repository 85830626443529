import React, { useState, useEffect, useRef, ReactNode } from 'react';
import wavesurferJs from 'wavesurfer.js';
import { ReactComponent as PlayIcon } from '../assets/play-icon.svg';
import { ReactComponent as PauseIcon } from '../assets/pause-icon.svg';
// @ts-ignore - Allow "implicit any" import of plugin not described in type file
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
// @ts-ignore - Allow "implicit any" import of plugin not described in type file
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import { useDispatch } from 'react-redux';
import Labels from '../assets/labels.json';
import LoadingWave from './LoadingWave';
import utils from '../utils/utils';
import { isDmrInterval, DmrIntervalEvent, ValidTimelineEvent } from '../interfaces/TimelineEvent';
import { WaveformError } from './WaveformError';
import './Waveform.css';
import { timelineEventFocused } from '../store/editor/editorSlice';
import { WaveformProvider, WaveformApi } from './WaveformContext';

export interface WaveformProps {
    /**
     * Specifies the location of the audio to be visualized.
     */
    audio: {
        url: string;
        scaledAmplitudes: number[];
        duration: number;
    };
    /**
     * This component uses named slots projection for its child composition strategy, as described
     * here: https://medium.com/@martin_hotell/react-children-composition-patterns-with-typescript-56dfc8923c64.
     */
    children: {
        /**
         * Provides the user information about the audio duration, etc.
         */
        clock: ReactNode,
        /**
         * Provides the user a way to change the editor state.
         */
        modeTransitioner: ReactNode,
    };
    /**
     * Informs message to display in case of audio-related errors. See WaveformError component.
     */
    errorCode?: string;
    /**
     * The list of events to be rendered in the visualization. Events must be valid.
     */
    events: ValidTimelineEvent[];
    /**
     * Each Waveform on a page must have a unique id, so that various elements created by this component can
     * be referred to uniquely.
     */
    id: string;
    /**
     * Is the audio ready (or has the user supplied enough info) for the waveform to be rendered, or are we just showing an empty waveform?
     */
    isAudioReady: boolean;
    /**
     * Waveforms are conditionally displayed rather than conditionally rendered because the latter would result
     * in unnecessarily re-downloading audio. Defaults to false.
     */
    isHidden?: boolean;
    /**
     * A message to be displayed while the file is being loaded.
     */
    loadingMsg?: string;
    /**
     * Provides a way to specify additional Wavesurfer event handlers.
     */
    on?: { event: string, callback: (...args: any[]) => void }[];
}

/**
 * Renders a visualization of the audio file and the timeline events that occur therein.
 *
 * Usage:
 * ```js
 * <Waveform audio={object} events={arrayOfValidEvents} id='unique-dom-id' isAudioReady={true}>
 *     {{
 *         clock,
 *         modeTransitioner,
 *     }}
 * </Waveform>
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const Waveform: React.FunctionComponent<WaveformProps> = (props) => {
    const { clock, modeTransitioner } = props.children;
    const { audio, errorCode, events, id, isAudioReady, on: eventHandlers = [] } = props;
    const isHidden = props.isHidden || false;

    // global state
    const dispatch = useDispatch();

    // local state
    const [audioDuration, setAudioDuration] = useState('--:--:--.---');
    const [waveSurferError, setWaveSurferError] = useState<string | Error>('');
    const [isLoading, setIsLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playheadPosition, setPlayheadPosition] = useState('00:00:00.000');

    // This is a bit hacky. isLoading is kept in local state and set to false upon completion of the
    // download of the first preview. This value persists when trying to load a second preview file.
    // As a result, the loading animation fails to appear for a second preview, and the play button
    // and other waveform child elements appear prematurely. To remedy this, isLoading is reset to its
    // default value any time isAudioReady is false (i.e., any time the browser hasn't received the
    // audio yet -- that is, when the file to visualize has changed).
    if (!isAudioReady && !isLoading) {
        setIsLoading(true);
    }

    /**
     * Holds a reference to the waveform object.
     *
     * A ref can be thought of as an instance variable in a class. Using one here ensures that
     * the work done to the wavesurfer object in the useEffect callback below isn't "trapped"
     * in that scope. Because a ref is used, the component is able to access wavesurfer methods
     * in callbacks for DOM events in the rendered JSX (they would be undefined otherwise).
     */
    const wavesurferRef = useRef<wavesurferJs>();

    const createRegionsFromEvents = (): void => {
        const wavesurfer = wavesurferRef.current;
        wavesurfer.clearRegions();

        const startEvents = events.filter((event) => {
            const isSecondInInterval = isDmrInterval(event) && !event.isFirstInInterval;
            return !isSecondInInterval;
        });

        const regionData = startEvents.flatMap((event) => {
            const regionStartTime = utils.convertEventTimeToSeconds(event.eventTime);

            const classNames: string[] = [];
            let regionEndTime = regionStartTime + 0.1;
            if (isDmrInterval(event)) {
                const secondInInterval = events.find((e) => {
                    const possiblePair = (e as DmrIntervalEvent);
                    return possiblePair.intervalKey === event.intervalKey && !possiblePair.isFirstInInterval;
                });
                if (secondInInterval) {
                    classNames.push('interval');
                    regionEndTime = utils.convertEventTimeToSeconds(secondInInterval.eventTime);
                } else {
                    // regions should be created only for intervals where both events are valid
                    return [];
                }
            }

            if (!classNames.includes('interval')) {
                classNames.push('single-timecode-event');
            }

            return [{
                params: {
                    drag: false,
                    end: regionEndTime,
                    id: event.id,
                    loop: false,
                    resize: false,
                    start: regionStartTime,
                },
                meta: {
                    classNames
                }
            }];
        });

        regionData.forEach((data) => {
            const { params, meta } = data;
            const region = wavesurfer.addRegion(params);
            region.element.innerHTML = '<svg class="timecode-indicator" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">\
                <path d="M0.855469 5.51128C0.855469 2.74986 3.09405 0.511279 5.85547 0.511279C8.61689 0.511279 10.8555 2.74986 10.8555 5.51128C10.8555 8.2727 7.78047 12.8613 5.85547 14.8113C4.03047 12.8613 0.855469 8.61128 0.855469 5.51128Z" />\
            </svg>';

            meta.classNames.forEach((className) => {
                region.element.classList.add(className);
            });
        });
    };

    const destroyWaveform = () => {
        const wavesurfer = wavesurferRef.current;
        wavesurfer.stop();
        wavesurfer.unAll();
        wavesurfer.destroy();
    };

    const waveformInitEffect = () => {
        const wavesurfer = wavesurferJs.create({
            backend: 'MediaElement',
            container: `#${id} .wave-form`,
            responsive: true,
            partialRender: false,
            waveColor: '#ffb800',
            progressColor: '#adadad',
            cursorColor: '#0079d0',
            barGap: 1,
            barWidth: 3,
            plugins: [
                TimelinePlugin.create({
                    container: `#${id} .wave-timeline`,
                }),
                RegionsPlugin.create({}),
            ]
        });

        wavesurfer.on('waveform-ready', () => {
            const maskedDuration = utils.convertSecondsToEventTime(audio.duration);
            setAudioDuration(maskedDuration);
            setIsLoading(false);
        });

        wavesurfer.on('finish', () => {
            setIsPlaying(false);
        });

        // fires when the user moves the playhead manually while the audio is NOT playing
        wavesurfer.on('seek', (seek) => {
            const seconds = wavesurfer.getCurrentTime();
            const normalizedPosition = utils.convertSecondsToEventTime(seconds);
            setPlayheadPosition(normalizedPosition);
        });

        // the doc says the callback should expect a string, but practical experience says that an Error
        // is also possible (e.g., if waveform.load() 404s)
        wavesurfer.on('error', (err: Error | string) => {
            setIsLoading(false);
            setWaveSurferError(err);
        });

        // fires continuously as the audio plays, as well as when the user moves the playhead manually while the audio is playing
        wavesurfer.on('audioprocess', (data) => {
            const normalizedPosition = utils.convertSecondsToEventTime(data);
            setPlayheadPosition(normalizedPosition);
        });

        // TODO: type data
        wavesurfer.on('region-click', (data) => {
            const id = data.id;
            dispatch(timelineEventFocused(id));
        });

        // TODO: type data
        wavesurfer.on('region-in', (data) => {
            const id = data.id;
            dispatch(timelineEventFocused(id));
        });

        // TODO: type data
        wavesurfer.on('region-out', (data) => {
            const id = data.id;
            // TODO: what's the desired behavior after playing through a break?
        });

        // TODO: type data
        wavesurfer.on('region-created', (data) => {
            // Disable default behavior of displaying timecode on hover.
            data.element.removeAttribute('title');
        });

        // custom event handling
        eventHandlers.forEach(({ event, callback }) => {
            wavesurfer.on(event, callback);
        });

        if (isAudioReady) {
            wavesurfer.load(audio.url, audio.scaledAmplitudes, 'metadata', audio.duration);
        }

        wavesurferRef.current = wavesurfer;

        return destroyWaveform;
    };
    // TODO: There's a bug with the session slice which is causing unnecessary re-rendering on session keepalive.
    useEffect(waveformInitEffect, [isAudioReady]);

    useEffect(createRegionsFromEvents, [events]);

    useEffect(
        () => {
            if (isHidden && isPlaying) {
                wavesurferRef.current.stop();
                setIsPlaying(false);
            }
        },
        [isHidden, isPlaying]
    );

    const renderClassNames = (): string => {
        const classNames = ['waveform', 'card'];

        if (isHidden) {
            classNames.push('hidden-off-screen');
        }

        if (isLoading) {
            classNames.push('waveform-loading');
        }

        if (errorCode || waveSurferError) {
            classNames.push('audio-error');
        }

        return classNames.join(' ');
    };

    /**
     * Play/pause the audio
     */
    const togglePlay = (): void => {
        wavesurferRef.current.playPause();
        setIsPlaying(wavesurferRef.current.isPlaying());
    };

    const waveformApi: WaveformApi = {
        getDuration: () => audioDuration,
        getPlayheadPosition: () => playheadPosition,
    };

    return (
        <WaveformProvider value={waveformApi}>
            <section className={renderClassNames()} id={id}>
                <WaveformError error={errorCode || waveSurferError} />
                <div className='slot-mode-transitioner'>{modeTransitioner}</div>
                <div className='wave-controls'>
                    {isPlaying
                        ?
                        <button className='pause' onClick={togglePlay} title={Labels.pauseButton}>
                            <PauseIcon />
                            Pause
                        </button>
                        :
                        <button className='play' onClick={togglePlay} title={Labels.playButton}>
                            <PlayIcon />
                            Play
                        </button>
                    }
                </div>
                <div className='slot-clock'>
                    {clock}
                </div>
                <div className='wave-form'></div>
                <div className='wave-timeline'></div>
                {isLoading && !errorCode && <LoadingWave loadingMessage={props.loadingMsg} />}
            </section>
        </WaveformProvider>
    );

};
