export interface AppConfig {
    analyticsAuth: string;
    analyticsContainerId: string;
    analyticsEnv: string;
    bakedInDurationDays: number;
    keepaliveMs: number;
    oktaClientId: string;
    oktaOrgUrl: string;
    serverHostname: string;
    serverPort: number;
}

let config: AppConfig;

/**
 * @throws if config couldn't be fetched.
 */
export const getConfig = async (): Promise<AppConfig> => {
    if (!config) {
        config = await fetch('/config.json').then(response => response.json());
    }
    return config;
};
