import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducer';
import Labels from '../assets/labels.json';
import './GlobalMessage.css';

/**
 * Displays messages stored in the global state.
 *
 * Usage:
 * ```js
 * <GlobalMessage />
 * ```
 */
// tslint:disable-next-line: variable-name - React components must be capitalized
export const GlobalMessage: React.FunctionComponent = () => {
    const globalMessageState = useSelector((state: RootState) => state.editor.globalMessage);

    if (!globalMessageState) {
        return null;
    }

    const { content, type } = globalMessageState;
    const msg = `${content}${type === 'error' ? ` ${Labels.loadSourceAudio.genericHelp}` : ''}`;

    return msg && (
        <section id='global-message' className={type}>
            <span className='message' dangerouslySetInnerHTML={{ __html: msg }}></span>
        </section>
    );
};
