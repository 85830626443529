import { TimelineEvent, isDmrInterval, DmrIntervalEvent } from '../interfaces/TimelineEvent';
import { getConfig } from '../utils/config';

/**
 * Converts a time string to a number of seconds.
 * The expected time string format is hh:mm:ss.SSS
 */
function convertEventTimeToSeconds(eventTime: string = ''): number {
    const parts = eventTime.split(':');
    const hourSeconds = parseInt(parts[0], 10) * 3600 || 0;
    const minuteSeconds = parseInt(parts[1], 10) * 60 || 0;
    const seconds = parseInt((parts[2] || '').split('.')[0], 10) || 0;
    const millis = parseInt((parts[2] || '').split('.')[1], 10) || 0;

    return hourSeconds + minuteSeconds + seconds + millis / 1000;
}


/**
 * Normalize a number of seconds (e.g 100.234) to
 * a string e.g. '00:12:34.234'
 */
function convertSecondsToEventTime(data: string | number): string {
    let progress = Number(parseFloat(data.toString()).toFixed(3));

    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let millis = 0;

    // find hours
    if (progress >= 3600) {
        hours = Math.trunc(progress / 3600);
        progress -= hours * 3600;
    }

    // find minutes
    if (progress >= 60) {
        minutes = Math.trunc(progress / 60);
        progress -= minutes * 60;
    }
    seconds = Math.trunc(progress);
    millis = Math.round((progress - seconds) * 1000);

    const hoursString = hours < 10 ? '0' + hours : hours;
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    const secondsString = seconds < 10 ? '0' + seconds : seconds;
    let millisString = '' + millis;
    if (millisString.length < 3) {
        millisString = '0' + millisString;
    }
    if (millisString.length < 3) {
        millisString = '0' + millisString;
    }

    return hoursString + ':' + minutesString + ':' + secondsString + '.' + millisString;
}

export function getPairedEvent(event: DmrIntervalEvent, pairCandidates: TimelineEvent[]): DmrIntervalEvent | undefined {
    return pairCandidates.find((candidate) => {
        return (candidate as DmrIntervalEvent).intervalKey === event.intervalKey
            && candidate.id !== event.id;
    }) as DmrIntervalEvent;
}

export function sortEvents(eventA: TimelineEvent, eventB: TimelineEvent): number {
    const eventATime = convertEventTimeToSeconds(eventA.eventTime);
    const eventBTime = convertEventTimeToSeconds(eventB.eventTime);
    return eventATime - eventBTime;
}

/**
 * Returns the start and end event for an interval if the interval key is provided,
 * otherwise returns an array with {startEvent, endEvent} pairs for each interval
 *
 * TODO: This should be refactored so there is only ever one return type, i.e.,
 * Array<{ startEvent: Event, endEvent: Event }>
 */
function getIntervalEvents(events: TimelineEvent[] = [], intervalKey?: string): { startEvent: DmrIntervalEvent, endEvent: DmrIntervalEvent } | any[] {
    if (intervalKey) {
        const intervalEvents = events.filter(event => isDmrInterval(event) && event.intervalKey === intervalKey) as DmrIntervalEvent[];

        let startEvent: DmrIntervalEvent;
        let endEvent: DmrIntervalEvent;
        if (intervalEvents && intervalEvents.length === 2) {
            intervalEvents.sort(sortEvents);
            startEvent = intervalEvents[0];
            endEvent = intervalEvents[1];
        }

        return { startEvent, endEvent };
    }

    const intervalEvents = events.filter(aEvent => isDmrInterval(aEvent)) as DmrIntervalEvent[];
    const intervalKeys = Array.from(new Set(intervalEvents.map(event => event.intervalKey)));
    return intervalKeys.map(intervalKey => getIntervalEvents(events, intervalKey));

}

async function getServerBaseURL(): Promise<string> {
    const cfg = await getConfig();

    const port = cfg.serverPort;
    if (!port) {
        throw new Error('Server port missing from app configuration.');
    }
    const hostname = cfg.serverHostname;
    if (!hostname) {
        throw new Error('Server hostname missing from app configuration.');
    }

    return `${window.location.protocol}//${hostname}:${port}`;
}

export default {
    convertEventTimeToSeconds,
    convertSecondsToEventTime,
    getServerBaseURL,
    getIntervalEvents,
    sortEvents,
};
