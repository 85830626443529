import React from 'react';
import Labels from '../assets/labels.json';
import { useDispatch } from 'react-redux';
import { previewRequested } from '../store/previewer/previewerSlice';

/**
 * Displays the button for transitioning to preview mode.
 *
 * Usage:
 * ```js
 * <PreviewButton disabled='false' />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const PreviewButton: React.FunctionComponent<{ disabled: boolean }> = (props) => {
    const dispatch = useDispatch();

    const doPreview = () => {
        dispatch(previewRequested());
    };

    return (
        <button className='primary transition-preview' disabled={props.disabled} onClick={doPreview}>{Labels.previewButton}</button>
    );
};
