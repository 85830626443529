import TagManager, { TagManagerArgs } from 'react-gtm-module';

export interface AnalyticsConfig {
    auth: string;
    /**
     * ID for the collection of tags, triggers, and other configs for an analyzed property such as a website.
     */
    containerId: string;
    /**
     * Used to identify the environment in which the app is deployed.
     */
    env: string;
}

export class Analytics {
    public static init(config: AnalyticsConfig): void {
        if (!config.containerId) {
            return;
        }

        const tagManagerArgs: TagManagerArgs = {
            gtmId: config.containerId,
        };

        if (config.auth && config.env) {
            tagManagerArgs.auth = config.auth;
            tagManagerArgs.preview = config.env;
        }

        TagManager.initialize(tagManagerArgs);
    }
}
