export const SESSION_ID = '@@session/id';
export const FILE_NAME = '@@session/fileName';
export const FILE_DURATION = '@@session/fileDuration';
export const SELECTED_EVENT_ID = '@@session/selectedEventId';

export const UPLOAD_FAILURE = '@@session/uploadFailure';
export const UPLOAD_SUCCESS = '@@session/uploadSuccess';

export const DOWNLOAD_FAILURE = '@@session/downloadFailure';
export const DOWNLOAD_SUCCESS = '@@session/downloadSuccess';

export const FETCH_METADATA_REQUEST = '@@session/fetchMetadataRequest';
export const FETCH_METADATA_FAILURE = '@@session/fetchMetadataFailure';
export const FETCH_METADATA_SUCCESS = '@@session/fetchMetadataSuccess';

export const SAVE_METADATA_REQUEST = '@@session/saveMetadataRequest';
export const SAVE_METADATA_FAILURE = '@@session/saveMetadataFailure';
export const SAVE_METADATA_SUCCESS = '@@session/saveMetadataSuccess';

export const FETCH_CONFIGURATION_REQUEST = '@@session/fetchConfigurationRequest';
export const FETCH_CONFIGURATION_FAILURE = '@@session/fetchConfigurationFailure';
export const FETCH_CONFIGURATION_SUCCESS = '@@session/fetchConfigurationSuccess';

export const FETCH_EVENTS_FROM_XML_REQUEST = '@@session/fetchEventsFromXMLRequest';
export const FETCH_EVENTS_FROM_XML_FAILURE = '@@session/fetchEventsFromXMLFailure';
