import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '..';
import {
    FetchAudioResponse,
    fetchConfiguration,
    FetchConfigurationResponse,
    AdProduct,
    isApiSuccess,
    fetchSponsors,
    publish,
} from '../../api/atmServer';
import Labels from '../../assets/labels.json';
import { validator, isIntervalValid } from '../../utils/validator';
import { sortEvents, getPairedEvent } from '../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { BaseTimelineEvent, TimelineEvent, DmrSingleEvent, DmrIntervalEvent, isDmrInterval } from '../../interfaces/TimelineEvent';
import { setSessionId } from '../session/actions';
import { SponsorData } from '../../interfaces/Sponsor';
import debounce from 'lodash.debounce';
import { add } from 'date-fns';

export enum EditorStageStates {
    /**
     * Metadata on the stage is valid and has not been edited since it was retrieved from the source audio file.
     */
    pristine,
    /**
     * Metadata on the stage is invalid.
     */
    invalid,
    /**
     * All changes have been validated.
     */
    valid,
    /**
     * The stage has not yet been initialized (this is the default state of the stage).
     */
    uninitialized,
    /**
     * The editor is locked (i.e., preview mode).
     */
    locked,
    /**
     * Changes are in the process of being published
     */
    publishing,
    /**
     * Changes have been published.
     */
    published,
}

export interface GlobalMessage {
    type: 'error'; // presently no other type of message is supported
    content: string;
}

export interface EditorState {
    /**
     * The timeline event with which the user is currently interacting.
     */
    activeEventId: string | null;
    /**
     * Whether or not the user has indicated intent to delete the active event.
     */
    activeEventIsProposedForDeletion: boolean;
    /**
     * The list of ad products the editor may present to the user.
     */
    adProductsList: AdProduct[];
    /**
     * Whether or not the configuration request is active.
     */
    configurationIsLoading: boolean;
    /**
     * A global message to display.
     */
    globalMessage: null | GlobalMessage;
    /**
     * The list of parent paths under which podcast audio might appear. ATM gets only a partial URI from
     * Seamus/the user; the server uses these to guess the full path of audio on the CDN. The client,
     * however, only needs to know about these in order to translate full URLs to Seamus partial URIs.
     */
    podcastDirectories: string[];
    /**
     * The current state of the stage.
     */
    stageState: EditorStageStates;
    /**
     * The list of timeline events the editor is tracking.
     */
    timelineEvents: TimelineEvent[];
    /**
     * The date of publication for a given episode
     */
    publishDate: number;
    /**
     * The date when sponsor industry exclusivity for ads expires
     */
    effectiveDate: number;
}

const initialState: EditorState = {
    activeEventId: null,
    activeEventIsProposedForDeletion: false,
    adProductsList: [],
    configurationIsLoading: false,
    globalMessage: null,
    podcastDirectories: [],
    stageState: EditorStageStates.uninitialized,
    timelineEvents: [],
    publishDate: null,
    effectiveDate: null
};

function initializeBaseEvent(): BaseTimelineEvent {
    return {
        eventTime: '',
        id: uuidv4(),
        isValid: false,
    };
}

/**
 * Helper function to sort events if the active row (i.e., the active event and its DMR pair, if one exists) is valid.
 */
function sortIfRowValid(activeEvent: TimelineEvent, allEvents: TimelineEvent[]):void {
    const events = [activeEvent];
    if (isDmrInterval(activeEvent)) {
        const pairedEvent = getPairedEvent(activeEvent, allEvents);
        if (pairedEvent) {
            events.push(pairedEvent);
        }
    }

    if (events.every(e => e.isValid)) {
        allEvents.sort(sortEvents);
    }
}

/**
 * Helper function to set validity for updated events as well as the stageState.
 *
 * Not all reducers which can affect stageState (e.g., deletionConfirmed) need to
 * update the validity for a specific event, so the parameter defaults empty.
 */
function updateValidity(state: EditorState, events: TimelineEvent[] = []): void {
    const intervalKeys: string[] = [];
    events.forEach((event) => {
        event.isValid = validator.isTimelineEventValid(event);

        if (isDmrInterval(event)) {
            const { intervalKey } = event;
            if (!intervalKeys.includes(intervalKey)) {
                intervalKeys.push(intervalKey);
            }
        }
    });

    let allIntervalsAreValid = true;
    intervalKeys.forEach((intervalKey) => {
        // (one of) the events may be newly created (i.e., not in state yet); first check if both events were passed in the events array
        const intervalEvents = events.filter(e => (e as DmrIntervalEvent).intervalKey === intervalKey) as DmrIntervalEvent[];

        if (intervalEvents.length === 1) {
            // the matching event needs to be retrieved from the state
            const pairedEvent = getPairedEvent(intervalEvents[0], state.timelineEvents);
            if (pairedEvent) {
                intervalEvents.push(pairedEvent);
            }
        }
        allIntervalsAreValid = allIntervalsAreValid && isIntervalValid(intervalEvents);
    });

    const allEventsAreValid = events.every(e => e.isValid) && state.timelineEvents.every(e => e.isValid);
    state.stageState = allEventsAreValid && allIntervalsAreValid ? EditorStageStates.valid : EditorStageStates.invalid;
}

const editorSlice = createSlice({
    initialState,
    name: 'editor',
    reducers: {
        newBreakAdded(state) {
            const intervalKey = uuidv4();
            const firstInPair: DmrIntervalEvent = {
                ...initializeBaseEvent(),
                intervalKey,
                effectiveDate: state.effectiveDate,
                isFirstInInterval: true,
                sponsor: [],
                type: null,
            };
            const secondInPair: DmrIntervalEvent = {
                ...firstInPair,
                id: uuidv4(),
                isFirstInInterval: false,
            };

            state.timelineEvents.unshift(secondInPair);
            state.timelineEvents.unshift(firstInPair);
            state.stageState = EditorStageStates.invalid;
        },
        deletionCanceled(state) {
            state.activeEventIsProposedForDeletion = false;
            state.activeEventId = null;
        },
        deletionConfirmed(state) {
            const eventIdsToDelete: string[] = [];

            const activeEvent = state.timelineEvents.find(event => event.id === state.activeEventId);
            eventIdsToDelete.push(activeEvent.id);

            if (isDmrInterval(activeEvent)) {
                const pairedEvent = getPairedEvent(activeEvent, state.timelineEvents);
                if (pairedEvent) {
                    eventIdsToDelete.push(pairedEvent.id);
                }
            }

            state.timelineEvents = state.timelineEvents.filter(event => !eventIdsToDelete.includes(event.id));
            state.activeEventIsProposedForDeletion = false;
            state.activeEventId = null;
            updateValidity(state);
        },
        deletionProposed(state, action: PayloadAction<string>) {
            const eventId = action.payload;
            if (!state.timelineEvents.find(event => event.id === eventId)) {
                throw `Invalid event ID ${eventId} proposed for deletion`;
            }

            state.activeEventId = eventId;
            state.activeEventIsProposedForDeletion = true;
        },
        dmrSponsorChanged(state, action: PayloadAction<SponsorData[]>) {
            const activeEvent = state.timelineEvents.find(event => event.id === state.activeEventId) as DmrIntervalEvent;
            activeEvent.sponsor = action.payload;

            const pairedEvent = getPairedEvent(activeEvent, state.timelineEvents) as DmrIntervalEvent;
            pairedEvent.sponsor = action.payload;

            updateValidity(state, [activeEvent, pairedEvent]);
            if (activeEvent.isValid && pairedEvent.isValid) {
                state.timelineEvents.sort(sortEvents);
            }
        },
        dmrTypeChanged(state, action: PayloadAction<number>) {
            const activeEvent = state.timelineEvents.find(event => event.id === state.activeEventId) as DmrSingleEvent | DmrIntervalEvent;
            activeEvent.type = action.payload;
            const selectedProduct = state.adProductsList.find(product => product.id === activeEvent.type);
            if (selectedProduct.numEvents < 1 || selectedProduct.numEvents > 2) {
                throw 'Ad product has an unsupported number of timeline events';
            }

            if (isDmrInterval(activeEvent)) {
                const pairedEvent = getPairedEvent(activeEvent, state.timelineEvents);

                switch (selectedProduct.numEvents) {
                    case 1:
                        // if the product type dictates a single-event DMR, drop the paired event
                        state.timelineEvents = state.timelineEvents.filter(event => event.id !== pairedEvent.id);
                        // convert to DmrSingleEvent
                        delete activeEvent.effectiveDate
                        delete activeEvent.intervalKey;
                        delete activeEvent.isFirstInInterval;
                        delete activeEvent.sponsor;
                        break;
                    case 2:
                        // keep the paired events in sync
                        pairedEvent.type = activeEvent.type;
                        break;
                }

                // update validity based only on events that haven't been dropped
                const events = [activeEvent];
                if (state.timelineEvents.includes(pairedEvent)) {
                    events.push(pairedEvent);
                }
                updateValidity(state, events);

                sortIfRowValid(activeEvent, state.timelineEvents);
            } else {
                let pairedEvent: DmrIntervalEvent;
                switch (selectedProduct.numEvents) {
                    case 1:
                        // nothing to do here
                        break;
                    case 2:
                        // if the product type dictates an interval, convert the original event and add a paired event
                        const intervalKey = uuidv4();
                        const dmrIntervalEvent: DmrIntervalEvent = Object.assign(activeEvent, {
                            intervalKey,
                            isFirstInInterval: true,
                            effectiveDate: state.effectiveDate,
                            sponsor: [],
                        });

                        pairedEvent = {
                            ...dmrIntervalEvent,
                            id: uuidv4(),
                            eventTime: '',
                            isFirstInInterval: false,
                        };
                        state.timelineEvents.push(pairedEvent);
                        break;
                }

                const events = [activeEvent];
                if (pairedEvent) {
                    events.push(pairedEvent);
                }
                updateValidity(state, events);
                sortIfRowValid(activeEvent, state.timelineEvents);
            }

        },
        editorStateChanged(state, action: PayloadAction<EditorStageStates>) {
            state.stageState = action.payload;
            if (action.payload === EditorStageStates.locked) {
                state.activeEventId = null;
            }
        },
        fetchConfigurationFailed(state, action: PayloadAction<keyof typeof Labels.loadSourceAudio.error>) {
            // TODO: Implement error handling
        },
        fetchConfigurationStarted(state) {
            state.configurationIsLoading = true;
        },
        fetchConfigurationSucceeded(state, action: PayloadAction<FetchConfigurationResponse>) {
            const { payload: { adProducts, podcastDirectories } } = action;

            state.adProductsList = adProducts;
            state.podcastDirectories = podcastDirectories;
            state.configurationIsLoading = false;
        },
        publishAudioFailed(state, action: PayloadAction<string>) {
            state.globalMessage = {
                type: 'error',
                content: action.payload,
            };
        },
        publishAudioStarted(state) {
            state.globalMessage = null;
            state.stageState = EditorStageStates.publishing
        },
        publishAudioSucceeded(state) {
            state.globalMessage = null;
            state.stageState = EditorStageStates.published;
        },
        /**
         * We need to keep the ad product list when the user wants to tag a new file
         */
        resetRequested(state) {
            return { ...initialState, adProductsList: state.adProductsList };
        },
        /**
         * This action does not rely on activeEventId because the timecode could be for either event in a DMR pair.
         */
        timecodeUpdated(state, action: PayloadAction<{ eventId: string, timecode: string }>) {
            const { eventId, timecode } = action.payload;
            const event = state.timelineEvents.find(event => event.id === eventId);
            event.eventTime = timecode;
            updateValidity(state, [event]);
            sortIfRowValid(event, state.timelineEvents);
        },
        timelineEventAddedFromFile(state, action: PayloadAction<TimelineEvent>) {
            const event = action.payload;
            if (isDmrInterval(event) && event.effectiveDate === null) {
                event.effectiveDate = state.effectiveDate;
            }
            state.timelineEvents.push(event);
            updateValidity(state, [event]);
            sortIfRowValid(event, state.timelineEvents);
        },
        timelineEventFocused(state, action: PayloadAction<TimelineEvent | string>) {
            const payload = action.payload;
            state.activeEventId = (payload as TimelineEvent).id ? (payload as TimelineEvent).id : payload as string;
        },
        fetchSessionIdSucceeded(state) {
            state.globalMessage = null;
        },
        fetchSessionIdFailed(state, action: PayloadAction<GlobalMessage>) {
            const { payload: message } = action;

            state.globalMessage = message;
        },
        publishDateSet(state, action: PayloadAction<number>) {
            state.publishDate = action.payload;
        },
        effectiveDateCalculated(state, action: PayloadAction<number>) {
            state.effectiveDate = action.payload;

            state.timelineEvents.forEach((event) => {
                if (isDmrInterval(event)) {
                    event.effectiveDate = state.effectiveDate;
                }
            });
        }
    },
});

export const {
    newBreakAdded,
    deletionCanceled,
    deletionConfirmed,
    deletionProposed,
    dmrTypeChanged,
    dmrSponsorChanged,
    editorStateChanged,
    fetchConfigurationFailed,
    fetchConfigurationStarted,
    fetchConfigurationSucceeded,
    publishAudioFailed,
    publishAudioStarted,
    publishAudioSucceeded,
    resetRequested,
    timecodeUpdated,
    timelineEventAddedFromFile,
    timelineEventFocused,
    fetchSessionIdSucceeded,
    fetchSessionIdFailed,
    publishDateSet,
    effectiveDateCalculated,
} = editorSlice.actions;

export const editorReducer = editorSlice.reducer;

// begin thunks
export const timelineEventsFetched = (
    response: FetchAudioResponse
): AppThunk => async (dispatch, getState) => {
    const { metadata } = response;

    const timelineEvents = metadata.events;
    timelineEvents.forEach((timelineEvent) => {
        dispatch(timelineEventAddedFromFile(timelineEvent));
    });

    // If timelineEventAddedFromFile or newBreakAdded was dispatched, the stageState will already have been set, either directly
    // in a reducer or indirectly by updateValidity. We can trust the reducers to have done their work, but we do
    // have to intervene to flag the stageState as "pristine," since the reducers are generally concerned with "valid"
    // vs. "invalid;" indeed, this thunk is the only place stageState can possibly be "pristine." If neither action was
    // dispatched, the stageState will still be uninitialized; it should be set to "pristine" since ATM does not require
    // events to be created for audio files.
    const stageState = getState().editor.stageState;
    if ([EditorStageStates.valid, EditorStageStates.uninitialized].includes(stageState)) {
        dispatch(editorStateChanged(EditorStageStates.pristine));
    }
};

export const fetchServerConfigs = (sessionId: string): AppThunk => async (dispatch) => {
    dispatch(fetchConfigurationStarted());

    const result = await fetchConfiguration(sessionId);
    if (isApiSuccess(result)) {
        dispatch(setSessionId(result.payload.sessionId));
        dispatch(fetchConfigurationSucceeded(result.payload));
    } else {
        dispatch(fetchConfigurationFailed(result.payload));
    }
};

export const debouncedFetchSponsors = debounce(
    async (name: string, sessionId: string, callback: any) => {
        const result = await fetchSponsors(name, sessionId);

        if (isApiSuccess(result)) {
            const { sponsors } = result.payload;
            callback(sponsors);
        }
    },
    500
);

export const publishAudio = (): AppThunk => async (dispatch, getState) => {
    dispatch(publishAudioStarted());

    const result = await publish(getState().audio.sessionId);
    if (isApiSuccess(result)) {
        dispatch(publishAudioSucceeded());
    } else {
        const reason = result.payload === 'badNetwork' ? Labels.publishFailedOffline : Labels.publishFailed;
        dispatch(publishAudioFailed(reason));
    }
};

export const publishDateUpdated = (unixTime: number): AppThunk => async (dispatch, getState) => {
    const { bakedInDurationDays } = getState().config;

    const publishDate = new Date(unixTime * 1000);
    const effectiveDate = add(publishDate, { days: bakedInDurationDays });

    dispatch(publishDateSet(unixTime));
    dispatch(effectiveDateCalculated(Math.round(effectiveDate.getTime() / 1000)));
};
