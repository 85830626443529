const baseUrl = 'https://ondemand.npr.org/';
const extensionPattern = /\.[a-z0-9]+$/;

export function getExtension(uri: string): string | null {
    return uri.match(extensionPattern)?.pop() || null;
}

/**
 * Converts a URL like https://ondemand.npr.org/anon.npr-podcasts/podcast/npr/show/2021/06/episode.mp3'
 * to the partial URI that Seamus expects (/podcast/npr/show/2021/06/episode). Note the removal of the
 * origin, the podcast parent path, and the file extension.
 */
export function seamusifyUri(uri: string, podcastPaths: string[]): string {
    const url = new URL(uri, baseUrl);
    const unProcessedPath = url.pathname;
    let path = unProcessedPath;

    for(const podcastPath of podcastPaths) {
        const pathPrefixPattern = new RegExp(`^/${podcastPath}/`);
        path = unProcessedPath.replace(pathPrefixPattern, '/');

        if (path !== unProcessedPath) {
            break;
        }
    }

    return path.replace(extensionPattern, '');
}

export function webifySeamusUri(cdnPath: string, seamusUri: string, ext: string): string {
    // Filtering these arrays removes cruft from splitting paths with leading or trailing slashes.
    const cdnPathParts = cdnPath.split('/').filter(item => item !== '');
    const seamusUriParts = `${seamusUri}${ext}`.split('/').filter(item => item !== '');

    // The CDN path always starts with a CDN storage group ID which is not used on the web -- drop it.
    cdnPathParts.shift();

    const newPathParts = [
        // from the original path, keep only the directories not already represented in the seamus URI
        ...cdnPathParts.slice(0, cdnPathParts.length - seamusUriParts.length),
        ...seamusUriParts,
    ];

    const url = new URL(newPathParts.join('/'), baseUrl);
    return url.toString();
}
