import React from 'react';
import { Link } from 'react-router-dom';
import Labels from '../assets/labels.json';
import './AppHeader.css';

/**
 * Renders the app header.
 * Contains the logo and the name.
 * Requires no props.
 *
 * Usage:
 * ```js
 * <AppHeader />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const AppHeader: React.FunctionComponent = () => {
    return (
        <header className='app-header'>
            <Link to='/'>
                <img src='https://media.npr.org/chrome_svg/npr-logo.svg' className='NPRLogo' alt='NPR' />
                {Labels.appName}
            </Link>
            <span className='help'>
                <a className='contact' href='https://digitalsupport.npr.org/' target='_blank' rel='noopener'>Contact</a>
            </span>
        </header>
    );
};
