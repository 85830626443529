import React from 'react';
import Labels from '../assets/labels.json';
import { useDispatch } from 'react-redux';
import { editorStateChanged, EditorStageStates } from '../store/editor/editorSlice';
import { previewDiscarded } from '../store/previewer/previewerSlice';

/**
 * Displays the button for transitioning to edit mode.
 *
 * Usage:
 * ```js
 * <EditButton />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const EditButton: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const goToEdit = () => {
        dispatch(editorStateChanged(EditorStageStates.valid));
        dispatch(previewDiscarded());
    };

    return (
        <button className='primary transition-edit' onClick={goToEdit}>{Labels.backToEditButton}</button>
    );
};
