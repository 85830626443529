import React from 'react';
import './MainHeader.css';
import { useSelector, useDispatch } from 'react-redux';
import Labels from '../assets/labels.json';
import { RootState } from '../store/reducer';
import { publishAudio } from '../store/editor/editorSlice';
import { DateDisplay } from './DateDisplay';

/**
 * Renders the main header for the editor.
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const MainHeader: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const canPublish = useSelector((state: RootState) => state.previewer.dmr.publishIsAllowed);
    const fileName = useSelector((state: RootState) => state.audio.fileName);
    const podcastIconUrl = useSelector((state: RootState) => state.audio.podcastConfig.podcastIconUrl);
    const podcastName = useSelector((state: RootState) => state.audio.podcastConfig.podcastName);

    const buildIconElement = (): JSX.Element | null => {
        if (!podcastIconUrl) {
            return null;
        }
        const url = new URL(podcastIconUrl);
        url.searchParams.append('s', '100');
        return (
            <img alt={podcastName}
              title={podcastName}
                className='icon'
                src={url.href}
            />
        );
    };

    const publishEvents = () => {
        dispatch(publishAudio());
    };

    return (
        <div className='main-header'>
            {buildIconElement()}
            <span className='file-name' title={fileName}>{fileName}</span>
            <span className='button-group'>
                {canPublish && <button className='btn-large primary publish' onClick={publishEvents}>
                    {Labels.publishButton}
                </button>}
                {(!canPublish && fileName !== '') && <DateDisplay/>} 
            </span>
        </div>
    );
};
