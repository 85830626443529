import React, { Fragment, useState } from 'react';
import MaskedInput from 'react-text-mask';
import Labels from '../assets/labels.json';
import { useDispatch } from 'react-redux';
import { ValidationError } from './ValidationError';
import { isTimecodeFormatValid, validator } from '../utils/validator';
import { timecodeUpdated } from '../store/editor/editorSlice';
import utils from '../utils/utils';

interface TimecodeProps {
    eventId: string;
    value: string;
    /**
     * The ceiling for the value.
     *
     * Useful for preventing a start time from being set to after its companion end time.
     */
    ceiling?: string;
    /**
     * The floor for the value.
     *
     * Useful for preventing an end time from being set to before its companion start time.
     */
    floor?: string;
    readonly?: boolean;
}

/**
 * Displays the timeline input for a timeline event.
 *
 * Usage:
 * ```js
 * <Timecode eventId='some-string' value='00:47:33.517' />
 * ```
 *
 * State updates are triggered on blur instead of the more typical change event. The primary reason for this
 * is that updating timecodes can result in reordering of rows, which would be very disorienting on every
 * keystroke.
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const Timecode: React.FunctionComponent<TimecodeProps> = (props) => {
    const dispatch = useDispatch();
    const err: string[] = [];

    /**
     * Updates the `err` array with any validation messages. If the array is empty, the value is valid.
     */
    const validate = (timecode: string): void => {
        if (!isTimecodeFormatValid(timecode)) {
            err.push(Labels.timecodeFormatInvalid);
        }

        if (timecode === '') {
            // no sense displaying other errors if the time hasn't been entered yet
            return;
        }

        if (!validator.isTimecodeInRange(timecode)) {
            err.push(Labels.timecodeOutOfRange);
        }

        if (
            props.ceiling
            && utils.convertEventTimeToSeconds(timecode) >= utils.convertEventTimeToSeconds(props.ceiling)
        ) {
            err.push(Labels.timecodeStartFirst);
        }

        if (
            props.floor
            && utils.convertEventTimeToSeconds(timecode) <= utils.convertEventTimeToSeconds(props.floor)
        ) {
            err.push(Labels.timecodeEndLast);
        }
    };
    // as this is run on each render, it is unnecessary to validate in the blur handler so long as it updates the state, causing a re-render
    validate(props.value);

    // no it's not just you; this is confusing: useState provides *local* component state, unrelated to our appstate slices
    const [isPristine, setIsPristine] = useState(true);

    const renderClassNames = (): string => {
        const classNames = ['timecode'];

        if (isPristine) {
            classNames.push('pristine');
        }

        if (err.length > 0) {
            classNames.push('invalid');
        }

        return classNames.join(' ');
    };

    const updateFieldValue = (domEvent: React.FocusEvent<HTMLInputElement>) => {
        setIsPristine(false);

        const fieldValue = domEvent.target.value;
        dispatch(timecodeUpdated({ eventId: props.eventId, timecode: fieldValue }));
    };

    const displayErrors = (): JSX.Element | string | null => {
        if (isPristine || err.length === 0) {
            return null;
        }

        if (err.length === 1) {
            return err[0];
        }

        const listItems = err.map(item => <li>{item}</li>);
        return <Fragment><p>Please correct the following errors:</p><ul>{listItems}</ul></Fragment>;
    };

    return (
        <Fragment>
            <MaskedInput
                className={renderClassNames()}
                defaultValue={props.value}
                // mask is 99:59:59.999, where each number represents the max value
                mask={[/\d/, /\d/, ':', /[0-5]/, /\d/, ':', /[0-5]/, /\d/, '.', /\d/, /\d/, /\d/]}
                onBlur={updateFieldValue}
                placeholder='00:00:00.000'
                placeholderChar='_'
                readOnly={!!props.readonly}
            />
            <ValidationError>{displayErrors()}</ValidationError>
        </Fragment>
    );
};
