import React from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import Labels from '../assets/labels.json';
import './AudioInputModal.css';
import { fetchAudioThunk, fileUriUpdated } from '../store/audio/audioSlice';
import TextInput from './TextInput';
import { RootState } from '../store/reducer';
import { useState } from 'react';
import { publishDateUpdated } from '../store/editor/editorSlice';
import { DateInput } from './DateInput';

// tslint:disable-next-line: variable-name - React function components must be capitalized
export const AudioInputModal: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const preflightErrorCode = useSelector((state: RootState) => state.audio.preflightErrorCode);
    const hasFileBeenFetched = useSelector((state: RootState) => !!state.audio.fileName);
    const { podcastDirectories } = useSelector((state: RootState) => state.editor);
    const [uri, setUri] = useState('');
    const [publishDate, setPublishDate] = useState<number>(undefined);
    const [isPristine, setIsPristine] = useState(true);
    const [isValid, setIsValid] = useState(false);

    // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#root');

    const fetchAudio = () => {
        dispatch(fileUriUpdated({ podcastDirectories, uri }));
        dispatch(fetchAudioThunk());
    };

    const getInputWrapperClass = (): string => {
        return `audioInputTextWrapper ${preflightErrorCode ? 'preFlightError' : ''}`;
    };

    const getDateInputWrapperClass = (): string => {
        const classNames = [`dateInputWrapper`];

        if (isPristine) {
            classNames.push('pristine')
        }

        if (!isValid) {
            classNames.push('invalid');
        }

        return classNames.join(' ');
    };

    const isModalOpen = () => {
        return !hasFileBeenFetched;
    };

    const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUri(e.target.value);
    }

    const dateChangeHandler = (date: number) => {
        setIsPristine(false);
        setIsValid(date !== undefined);
        setPublishDate(date);
        dispatch(publishDateUpdated(date));
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        setIsPristine(false);

        if (isValid) {
            fetchAudio();
        } 
    };

    const textInputProps = {
        maxlength: '1024',
        id: 'uri',
        autoFocus: true,
        placeholder: Labels.audioInputPlaceholder,
        onTextChange: inputChangeHandler,
    };

    return (
        <Modal
            isOpen={isModalOpen()}
            className='audioInputModal'
            overlayClassName='overlay'
        >
            <form onSubmit={onSubmit}>
                <div className={getInputWrapperClass()}>
                    <TextInput {...textInputProps} />
                    { preflightErrorCode &&
                        <div className='message rounded'>
                            <p dangerouslySetInnerHTML={{ __html: Labels.loadSourceAudio.error[preflightErrorCode] }}></p>
                            <p dangerouslySetInnerHTML={{ __html: Labels.loadSourceAudio.genericHelp }}></p>
                        </div>
                    }
                </div>
                <div className={getDateInputWrapperClass()}>
                    <DateInput onChangeAction={dateChangeHandler} value={publishDate}/>
                    { (!isValid && !isPristine) &&
                        <div className="message rounded">
                            <p>
                                <span className="msgHeading">
                                    ATM needs an episode publication date. 
                                </span>
                                <br/>
                                <br/>
                                This is for calculating when baked-in sponsorship is automatically converted to dynamic sponsorship.
                            </p>
                        </div>
                    }
                </div>
                <button type='submit' className='btn-large primary ml-20' >{Labels.goButton}</button>
            </form>
        </Modal>
    );
};