import { SponsorData } from './Sponsor';

export interface BaseTimelineEvent {
    eventTime: string;
    /**
     * A unique ID used only on the client, to facilitate wiring actions in the UI to actions in the state model.
     */
    id: string;
    isValid: boolean;
}

export interface DmrSingleEvent extends BaseTimelineEvent {
    /**
     * Identifies the product type by its id
     */
    type: number;
}

export interface DmrIntervalEvent extends DmrSingleEvent {
    effectiveDate: number | null;
    intervalKey: string;
    isFirstInInterval?: boolean;
    sponsor: SponsorData[];
}

export type TimelineEvent = DmrSingleEvent | DmrIntervalEvent;

export type ValidTimelineEvent = TimelineEvent & { isValid: true };

export function isDmr(event: TimelineEvent): event is DmrSingleEvent | DmrIntervalEvent {
    return isDmrSingle(event) || isDmrInterval(event);
}

export function isDmrSingle(event: TimelineEvent): event is DmrSingleEvent {
    return (event as DmrSingleEvent).type !== undefined && (event as DmrIntervalEvent).intervalKey === undefined;
}

export function isDmrInterval(event: TimelineEvent): event is DmrIntervalEvent {
    const candidate = event as DmrIntervalEvent;
    return (
        (typeof candidate.type === 'number' || candidate.type === null) &&
        typeof candidate.intervalKey === 'string' &&
        // TODO: a more thorough test of sponsor
        candidate.sponsor !== undefined &&
        (typeof candidate.effectiveDate === 'number' || candidate.effectiveDate === null)
    );
}
