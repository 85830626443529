import { combineReducers } from 'redux';

import { audioReducer } from './audio/audioSlice';
import session from './session/reducers';
import { editorReducer } from './editor/editorSlice';
import { configReducer } from './config/configSlice';
import { previewerReducer } from './previewer/previewerSlice';

export const rootReducer = combineReducers({
    session,
    audio: audioReducer,
    config: configReducer,
    editor: editorReducer,
    previewer: previewerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
