import React from 'react';
import { Waveform, WaveformProps } from './Waveform';
import { PreviewButton } from './PreviewButton';
import { Clock } from './Clock';
import { EditorStageStates } from '../store/editor/editorSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducer';
import { ValidTimelineEvent } from '../interfaces/TimelineEvent';

type EditWaveformProps = Omit<WaveformProps, 'audio'|'children'|'events'|'isAudioReady'|'isHidden'>;

/**
 * Composes a Waveform suitable for editing mode.
 *
 * A major motivation for this component is to encapsulate Waveform's state requirements. Creating
 * selectors in AppMain to power the Waveform component means that each waveform-relevant state
 * change would cause the entire app to be re-rendered unnecessarily. By contrast, encapsulating
 * those selectors here means only the waveform (and its children) are re-rendered.
 *
 * Usage:
 * ```js
 * <EditWaveform events={myListOfTimelineEvents} id='unique-dom-id' />
 * ```
 */
// tslint:disable-next-line: variable-name - React components must be capitalized
export const EditWaveform: React.FunctionComponent<EditWaveformProps> = (props) => {
    const sessionId = useSelector((state: RootState) => state.session.sessionId);
    const scaledAmplitudes = useSelector((state: RootState) => state.audio.scaledAmplitudes);
    const duration = useSelector((state: RootState) => state.audio.duration);
    const serverHostname = useSelector((state: RootState) => state.config.serverHostname);
    const serverPort = useSelector((state: RootState) => state.config.serverPort);

    const url = new URL('api/file', `${window.location.protocol}//${serverHostname}:${serverPort}`);
    url.searchParams.set('sid', sessionId);
    const audio = { duration, scaledAmplitudes, url: url.toString() };

    const events = useSelector((state: RootState) => state.editor.timelineEvents.filter(e => e.isValid)) as ValidTimelineEvent[];
    const isAudioReady = !!useSelector((state: RootState) => state.audio.fileName);

    const editingStageStates = [EditorStageStates.pristine, EditorStageStates.invalid, EditorStageStates.valid];
    const isEditMode = useSelector((state: RootState) => editingStageStates.includes(state.editor.stageState));

    const validStageStates = [EditorStageStates.pristine, EditorStageStates.valid];
    const stageIsValid = useSelector((state: RootState) => validStageStates.includes(state.editor.stageState));

    return (
        <Waveform audio={audio} events={events} id={props.id} isAudioReady={isAudioReady} isHidden={!isEditMode}>
            {{
                clock: <Clock />,
                modeTransitioner: <PreviewButton disabled={!stageIsValid} />,
            }}
        </Waveform>
    );
};
