import React, { Fragment } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/reducer';

import Labels from '../assets/labels.json';
import { DmrEventsTable } from './DmrEventsTable';
import './SummaryModal.css';
import { EditorStageStates, resetRequested as editorStateResetRequested } from '../store/editor/editorSlice';
import { resetRequested as audioStateResetRequested } from '../store/audio/audioSlice';
import { resetRequested as previewerStateResetRequeusted } from '../store/previewer/previewerSlice';
import { setSessionId } from '../store/session/actions';
import { webifySeamusUri } from '../utils/uri';


// tslint:disable-next-line: variable-name - React function components must be capitalized
export const SummaryModal: React.FunctionComponent = () => {
    const uri = useSelector((state: RootState) => state.audio.seamusUri);
    const hasAdFreeVariant = useSelector((state: RootState) => state.previewer.adFree.duration !== 0);
    const isPublished = useSelector((state: RootState) => state.editor.stageState === EditorStageStates.published);
    const { cdnPath, fileExt, seamusUri } = useSelector((state: RootState) => state.audio);
    const dispatch = useDispatch();

    const adFreeUrl = webifySeamusUri(cdnPath, `${seamusUri}.ad-free`, fileExt);

    // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#root');

    const isModalOpen = () => {
        return isPublished;
    };

    const copyAudioPath = () => {
        const audioPath: HTMLInputElement = document.querySelector('.audio-path-input');
        audioPath.select();
        document.execCommand('copy');
    };

    const resetState = () => {
        dispatch(setSessionId(undefined));
        dispatch(audioStateResetRequested());
        dispatch(editorStateResetRequested());
        dispatch(previewerStateResetRequeusted());
    };

    return (
        <Modal
            isOpen={isModalOpen()}
            className='modal-centered modal-publish-summary'
            overlayClassName='overlay'>

            <h2 className='my-20'>
                Success!
            </h2>
            <p className='my-10'>
                Your audio file has been tagged for sponsorship. Here is the break information for your records.
            </p>
            <div className='my-20'>
                <DmrEventsTable readonly />
            </div>
            <p className='my-5'>
                Here is the audio path you should use in your podcast episode:
            </p>
            <div className='my-5 audio-path'>
                <input className='audio-path-input w-100' type='text' value={uri} readOnly />
            </div>
            <div>
                <button className='btn-small secondary copy-path' onClick={copyAudioPath}>
                    {Labels.copyFileButton}
                </button>
            </div>
            {hasAdFreeVariant && <Fragment>
                <p className='my-5 audio-path-adFree'>
                    Here is the audio path you should use in your Plus podcast episode. Please do not add this URL to Seamus.<br />
                    <a href={adFreeUrl} target='_blank' rel='noopener'>{adFreeUrl}</a>
                </p>
            </Fragment>
            }
            <div className='button-group my-20'>
                <button className='btn-medium primary ml-10 tag-new-file' onClick={resetState}>
                    {Labels.tagFileButton}
                </button>
            </div>
        </Modal>
    );
};
