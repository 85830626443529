import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimelineEvent } from '../../interfaces/TimelineEvent';
import { AppThunk } from '..';
import { setFileMetadata, isApiSuccess, fetchPreview, FetchPreviewItem } from '../../api/atmServer';
import { editorStateChanged, EditorStageStates } from '../editor/editorSlice';

type PreviewErrorType = 'breaks' | 'unknown';

interface SinglePreviewerState {
    /**
     * Duration, in seconds, of the preview audio file.
     */
    duration: number;
    errorCode: PreviewErrorType | null;
    /**
     * Indicates whether or not the preview audio is ready to be downloaded into a waveform component.
     */
    isAudioReady: boolean;
    /**
     * Indicates whether or not the user should be allowed to publish.
     */
    publishIsAllowed: boolean;
    /**
     * "Peaks" data for building a waveform visualization.
     */
    scaledAmplitudes: number[];
    /**
     * The list of timeline events in the preview audio.
     */
    timelineEvents: TimelineEvent[];
}

export interface PreviewerState {
    adFree: SinglePreviewerState;
    dmr: SinglePreviewerState;
}

export type PreviewRel = keyof PreviewerState;

const initialState: PreviewerState = {
    adFree: {
        duration: 0,
        errorCode: null,
        isAudioReady: false,
        publishIsAllowed: false,
        scaledAmplitudes: [],
        timelineEvents: [],
    },
    dmr: {
        duration: 0,
        errorCode: null,
        isAudioReady: false,
        publishIsAllowed: false,
        scaledAmplitudes: [],
        timelineEvents: [],
    },
};

const previewerSlice = createSlice({
    initialState,
    name: 'previewer',
    reducers: {
        previewDiscarded() {
            return initialState;
        },
        previewDownloaded(state, action: PayloadAction<FetchPreviewItem[]>) {
            action.payload.forEach(item => {
                const { rel, duration, scaledAmplitudes } = item;
                state[rel].duration = duration;
                state[rel].scaledAmplitudes = scaledAmplitudes;
                state[rel].isAudioReady = true;
            });
        },
        previewRendered(state, action: PayloadAction<PreviewRel>) {
            const { payload: rel } = action;
            state[rel].publishIsAllowed = true;
        },
        previewFailed(state, action: PayloadAction<PreviewErrorType>) {
            state.adFree.errorCode = action.payload;
            state.dmr.errorCode = action.payload;
        },
        resetRequested() {
            return initialState;
        },
    },
});

export const {
    previewDiscarded,
    previewDownloaded,
    previewFailed,
    previewRendered,
    resetRequested,
} = previewerSlice.actions;

export const previewerReducer = previewerSlice.reducer;

// begin thunks
export const previewRequested = (): AppThunk => async (dispatch, getState) => {
    dispatch(editorStateChanged(EditorStageStates.locked));

    const state = getState();
    const { editor: { timelineEvents }, session: { sessionId } } = state;

    const setMetadataResult = await setFileMetadata(timelineEvents, sessionId);
    if (!isApiSuccess(setMetadataResult)) {
        dispatch(previewFailed('breaks'));
        return;
    }

    const fetchPreviewResult = await fetchPreview(sessionId);

    if (isApiSuccess(fetchPreviewResult)) {
        dispatch(previewDownloaded(fetchPreviewResult.payload));
    } else {
        dispatch(previewFailed('unknown'));
    }
};
