import React, { useState } from 'react';
import { Waveform, WaveformProps } from './Waveform';
import Labels from '../assets/labels.json';
import { EditButton } from './EditButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/reducer';
import { ValidTimelineEvent } from '../interfaces/TimelineEvent';
import { EditorStageStates } from '../store/editor/editorSlice';
import { PreviewRel, previewRendered } from '../store/previewer/previewerSlice';
import { DateDisplay } from './DateDisplay';

type PreviewWaveformProps = Omit<WaveformProps, 'audio'|'children'|'errorCode'|'events'|'isAudioReady'|'isHidden'|'loadingMsg'> & {
    rel: PreviewRel;
};

/**
 * Composes a Waveform suitable for preview mode.
 *
 * A major motivation for this component is to encapsulate Waveform's state requirements. Creating
 * selectors in AppMain to power the Waveform component means that each waveform-relevant state
 * change would cause the entire app to be re-rendered unnecessarily. By contrast, encapsulating
 * those selectors here means only the waveform (and its children) are re-rendered.
 *
 * Usage:
 * ```js
 * <PreviewWaveform id='unique-dom-id' rel='dmr' />
 *
 * ```
 */
// tslint:disable-next-line: variable-name - React components must be capitalized
export const PreviewWaveform: React.FunctionComponent<PreviewWaveformProps> = (props) => {
    const { rel } = props;
    const {
        duration,
        errorCode,
        isAudioReady,
        scaledAmplitudes,
        timelineEvents,
    } = useSelector((state: RootState) => state.previewer[rel]);
    const sessionId = useSelector((state: RootState) => state.session.sessionId);
    const { serverHostname, serverPort } = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();

    const [previewCount, setPreviewCount] = useState(0);
    const url = new URL('api/file', `${window.location.protocol}//${serverHostname}:${serverPort}`);
    url.search = new URLSearchParams({
        // previewCount is just used to change the URL of each request so that browsers don't cache responses; this ensures that the user
        // hears the latest audio when doing multiple preview iterations in a session
        previewCount: previewCount.toString(),
        context: rel,
        sid: sessionId,
    }).toString();

    const audio = { duration, scaledAmplitudes, url: url.toString() };

    const events = timelineEvents.filter(e => e.isValid) as ValidTimelineEvent[];
    const isPreviewMode = useSelector((state: RootState) => state.editor.stageState === EditorStageStates.locked);

    const waveformProps = {
        audio,
        errorCode,
        events,
        isAudioReady,
        id: props.id,
        isHidden: !isPreviewMode,
        loadingMsg: '',
        on: [
            {
                event: 'waveform-ready',
                callback: () => {
                    dispatch(previewRendered(rel));
                    setPreviewCount(previewCount + 1);
                },
            },
        ],
    };

    return (
        <Waveform { ... waveformProps }>
            {{
                clock: <div><span className="preview-header-text">{Labels.previewClock}</span><DateDisplay/></div>, 
                modeTransitioner: <EditButton />,
            }}
        </Waveform>
    );
};
