import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { toDate, format } from 'date-fns-tz';
import './DateInput.css';
import 'react-datepicker/dist/react-datepicker.css'

type DateInputProps = {
    onChangeAction: (date: number) => void;
    onBlur?: () => {};
    onFocus?: () => {};
    value: number;
};

/**
 * @param props
 * This component is for DateInput.
 * the onChangeAction prop is a function passed from a parent component to this one.
 * That function should expect to receive a date represented in UTC number format as its parameter
 *
 * OnFocus the type of this input field will change from text to date
 * OnBlur the type of this input field will change from date to text
 *
 * This field will only be editable when in date type, not while in text.
 */
export const DateInput = (props: DateInputProps) => {
    const [selectedDate, selectDate] = useState(null);

    /**
     * There are functions used from the date-fns-tz library and the way they work together is not entirely clear to me.
     * The purpose of this function is to take a date from any timezone. Extract the date string to yyyy-MM-dd format (e.g. 2022-03-08).
     * Then using that string create a new Date object where the date is 2022-03-08 and the time is Midnight Eastern Time.
     *
     * @param value a Date that is set in the local timezone of the client
     * @returns The value returned by this function needs to be the time represented as Unix Timestamp (s)
     */
    const toEasternTime = (value: Date): number =>  {
        selectDate(value);

        // Extract the date string from the local datetime to yyyy-MM-dd format. Note that it is important to use the local
        // date because the timestamp of the date the user entered may be in a different day depending on local timezone.
        // If a user indicates that the show will run on Dec 1, they mean that the show will run on Dec 1 at midnight in
        // Eastern time, regardless of where in the world they were when they supplied this data to ATM.
        const formattedDateString = format(value, 'yyyy-MM-dd');
        const midnightEasternDate = toDate(`${formattedDateString}`, { timeZone: 'America/New_York'});

        return  Math.round(midnightEasternDate.getTime() / 1000);
    }

    const onChange = (date: Date): void => {
        if (date !== null) {
            props.onChangeAction(toEasternTime(date));
        } else {
            props.onChangeAction(undefined);
        }
    }

    /**
     * @return this function will either return a date or a null value.
     * When this function returns a date, the date will be in the local timezone of the client.
     * When this function returns null, the DatePicker component will display the value passed in through the placeholderText prop
     */
    const getValue = (): Date | null => selectedDate !== null ? toDate(selectedDate) : null;

    /**
     * The date captured is persisted in Eastern time. The date displayed needs to be in local time. 
     * If the user enters March 15 and they are in LA, the box will display March 14, because Midnight March 15 eastern time is still March 14 in pacific time. 
     * This is written such that if a user enters March 15, they will see March 15 regardless of their timezone but the value persisted will be March 15 eastern time.
     */
    return <DatePicker 
        className="dateInput"
        selected={getValue()} 
        onChange={onChange} 
        placeholderText="Episode Publication Date"/>
}
