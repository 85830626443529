import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import './DateDisplay.css';

export const DateDisplay: React.FunctionComponent = () => {
    const publishTimestamp = useSelector((state: RootState) =>
        typeof state.editor.publishDate === 'number' ? state.editor.publishDate * 1000 : undefined);

    const effectiveDateTimestamp = useSelector((state: RootState) =>
        typeof state.editor.effectiveDate === 'number' ? (state.editor.effectiveDate * 1000) : undefined);

    /**
     * Publish dates should always be displayed in the timezone of NPR HQ. If we don't force the timestamp
     * to America/New_York, users in Los Angeles will see the previous day in the GUI, even though the
     * timestamp embedded in the ID3 tags is correct.
     */
    return (
        typeof publishTimestamp === 'undefined' || typeof effectiveDateTimestamp === 'undefined'
            ? null
            : <div>
                <div className="publishDateDisplay">
                    {`Publish Date: ${formatInTimeZone(publishTimestamp, 'America/New_York', 'MMM dd, Y')}`}
                </div>
                <div className="effectiveDateDisplay">
                    {`Baked-in Replacement Date: ${formatInTimeZone(effectiveDateTimestamp, 'America/New_York', 'MMM dd, Y')}`}
                </div>
            </div>
    );
};
