import axios, { AxiosResponse } from 'axios';
import Labels from '../assets/labels.json';
import utils from '../utils/utils';
import { TimelineEvent } from '../interfaces/TimelineEvent';
import { SponsorData } from '../interfaces/Sponsor';

export enum Endpoints {
    configuration = 'api/configuration',
    file = 'api/file',
    metadata = 'api/file/metadata',
    preview = 'api/file/preview',
    publish = 'api/file/publish',
    session = 'api/session',
    sponsors = 'api/sponsors',
}

export interface PodcastConfig {
    podcastIconUrl: string;
    podcastId: number;
    podcastName: string;
}

export interface AdProduct {
    id: number;
    isActive: boolean;
    name: string;
    type: string;
    version: string;
    maxLength?: string;
    maxNbrAds?: string;
    numEvents: number;
}

interface ApiSuccess<T> {
    payload: T;
    success: true;
}

interface ApiFailure {
    payload: keyof typeof Labels.loadSourceAudio.error;
    success: false;
}

export type ApiResponse<T> = ApiFailure | ApiSuccess<T>;

export function isApiSuccess<T>(response: ApiResponse<T>): response is ApiSuccess<T> {
    return response.success;
}

export interface FetchAudioResponse {
    config: PodcastConfig;
    metadata: {
        cdnPath: string;
        duration: number;
        events: TimelineEvent[];
        fileSize: number;
        fileName: string;
    };
    scaledAmplitudes: number[];
    sessionId: string;
}

export interface CreateOrExtendSessionResponse {
    data: string;
}

export interface FetchConfigurationResponse {
    adProducts: AdProduct[];
    podcastDirectories: string[];
    sessionId: string;
}

export interface FetchPreviewItem {
    rel: 'adFree' | 'dmr';
    duration: number;
    scaledAmplitudes: number[];
}

export interface FetchSponsorsResponse {
    sponsors: SponsorData[];
}

interface HeaderFields {
    accessToken?: string;
    sessionId?: string;
}

interface RequestData {
    endpoint: string;
    headers: HeaderFields;
    payload?: any;
    requestParams?: Record<string, string>;
}

function buildHeader(sessionId?: string, accessToken?: string): { Authorization?: string, 'x-session-id'?: string } {
    let header = {};

    if (accessToken) {
        header = {
            Authorization: `Bearer ${accessToken}`,
        };

        return header;
    }

    if (sessionId) {
        return {
            ...header,
            'x-session-id': sessionId
        };
    }

    return header;
}

/**
 * Helper function to centralize string operations for building URLs.
 */
async function buildEndpointUrl(endpoint: string, params?: Record<string, string>): Promise<string> {
    const baseUrl = await utils.getServerBaseURL();

    const url = new URL(baseUrl);
    url.pathname = endpoint;

    if (params) {
        const urlParams = new URLSearchParams(params);
        url.search = urlParams.toString();
    }
    
    return url.href;
}

async function get<T = any>(data: RequestData):
Promise<AxiosResponse<T>> {
    const { endpoint, requestParams: params, headers: { sessionId } } = data;

    const url = await buildEndpointUrl(endpoint, params);
    const headers = buildHeader(sessionId);
    return await axios.get(url, { headers });
}

async function post<T = any>(data: RequestData): Promise<AxiosResponse<T>> {
    const { endpoint, headers: { sessionId }, payload } = data;

    const url = await buildEndpointUrl(endpoint);
    const headers = buildHeader(sessionId);
    return await axios.post(url, payload, { headers });
}

async function put<T = any>(data: RequestData): Promise<AxiosResponse<T>> {
    const { endpoint, headers: { sessionId, accessToken }, payload } = data;

    const url = await buildEndpointUrl(endpoint);
    const headers = buildHeader(sessionId, accessToken);

    return await axios.put(url, payload, { headers });
}

export async function fetchAudio(uri: string, sessionId: string): Promise<ApiResponse<FetchAudioResponse>> {
    try  {
        const requestData: RequestData = {
            endpoint: Endpoints.file,
            headers: {
                sessionId
            },
            payload: { uri }
        };

        const result = await post<FetchAudioResponse>(requestData);

        return {
            payload: result.data,
            success: true,
        };
    } catch (err) {
        // This catch block adapted from https://github.com/axios/axios#handling-errors.
        if (err.response) {
            // The server responded with a non-success code.
            const response: AxiosResponse = err.response;

            // TODO: there are probably more cases to handle here (with more granularity), and this probably can't be
            // done until the endpoint stabilizes.
            switch (response.status) {
                case 404:
                    return {
                        payload: 'badPath',
                        success: false,
                    };
                case 500:
                    return {
                        // TODO: This is a placeholder for failed backups. Another error type is required to better tailor the
                        // message. See DIG-17611 and DIG-17023.
                        payload: 'badNetwork',
                        success: false,
                    };
                case 502:
                    return {
                        payload: 'badFile',
                        success: false,
                    };
            }
        }

        if (err.request) {
            // The request was made but no response was received.
            return {
                payload: 'badNetwork',
                success: false,
            };
        }

        // Something happened in setting up the request that triggered an error.
        console.log('Error', err.message);
        // TODO: what is a useful message for an end user here?
        return {
            payload: 'badNetwork',
            success: false,
        };
    }
}

export async function createOrExtendSession(sessionId?: string, accessToken?: string): Promise<ApiResponse<CreateOrExtendSessionResponse>> {
    try {
        const requestData: RequestData = {
            endpoint: Endpoints.session,
            headers: { }
        };

        if (sessionId) {
            requestData.headers.sessionId = sessionId;
        }

        if (accessToken) {
            requestData.headers.accessToken = accessToken;
        }

        const result = await put<CreateOrExtendSessionResponse>(requestData);

        return {
            payload: result.data,
            success: true,
        };
    } catch (err) {

        if (err.response) {
            // The server responded with a non-success code.
            const response: AxiosResponse = err.response;

            if (response.status === 401) {
                // TODO: The interface for ApiResponse is too tightly coupled to the load audio workflow; "payload" should be
                // less restrictive
                return {
                    payload: 'unauthorized',
                    success: false,
                };
            }
        }

        // Something happened in setting up the request that triggered an error.
        console.log('Error', err.message);
        return {
            payload: 'unknown',
            success: false,
        };

    }
}

export async function fetchConfiguration(sessionId: string): Promise<ApiResponse<FetchConfigurationResponse>> {
    try {
        const requestData: RequestData = {
            endpoint: Endpoints.configuration,
            headers: {
                sessionId
            }
        };

        const result = await get<FetchConfigurationResponse>(requestData);

        return {
            payload: result.data,
            success: true,
        };
    } catch (err) {
        // Something happened while requesting the configuration from the server that triggered an error.
        console.log('Error ', err.message);
        return {
            payload: 'badNetwork',
            success: false,
        };
    }
}

export async function fetchPreview(sessionId: string): Promise<ApiResponse<FetchPreviewItem[]>> {
    try {
        const requestData: RequestData = {
            endpoint: Endpoints.preview,
            headers: {
                sessionId
            }
        };

        const result = await post<FetchPreviewItem[]>(requestData);

        return {
            payload: result.data,
            success: true,
        };
    } catch (err) {
        console.log('Error ', err.message);

        return {
            // TODO: The interface for ApiResponse is too tightly coupled to the load audio workflow; "payload" should be
            // less restrictive
            payload: 'badNetwork',
            success: false,
        };
    }
}

export async function fetchSponsors(name: string, sessionId: string): Promise<ApiResponse<FetchSponsorsResponse>> {
    try {
        const requestData: RequestData = {
            endpoint: Endpoints.sponsors,
            headers: {
                sessionId
            },
            requestParams: {
                name
            }
        };

        const result = await get<FetchSponsorsResponse>(requestData);

        return {
            payload: result.data,
            success: true,
        };
    } catch (err) {
        console.log('Error ', err.message);
        return {
            payload: 'badNetwork',
            success: false
        };
    }
}

export async function publish(sessionId: string): Promise<ApiResponse<string>> {
    try {
        const requestData: RequestData = {
            endpoint: Endpoints.publish,
            headers: {
                sessionId
            }
        };

        const result = await post<string>(requestData);

        return {
            payload: result.data,
            success: true,
        };
    } catch (err) {
        if (err.message === 'Network Error') {
            return {
                // TODO: The interface for ApiResponse is too tightly coupled to the load audio workflow; "payload" should be
                // less restrictive
                payload: 'badNetwork',
                success: false,
            };
        }

        console.error(err);
        return {
            // TODO: The interface for ApiResponse is too tightly coupled to the load audio workflow; "payload" should be
            // less restrictive
            payload: 'unknown',
            success: false,
        };
    }
}

export async function setFileMetadata(events: TimelineEvent[], sessionId: string): Promise<ApiResponse<string>> {
    try {
        const requestData: RequestData = {
            endpoint: Endpoints.metadata,
            headers: {
                sessionId
            },
            payload: events,
        };

        const result = await put<string>(requestData);
        return {
            payload: result.data,
            success: true,
        };
    } catch (err) {
        console.log('Error setting file metadata:', err.message);
        return {
            // TODO: The interface for ApiResponse is too tightly coupled to the load audio workflow; "payload" should be
            // less restrictive
            payload: 'badNetwork',
            success: false,
        };
    }
}
