import React, { Fragment } from 'react';
import './WaveformError.css';

/**
 * Renders the Waveform error section.
 *
 * Usage:
 * ```js
 * <WaveformError />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const WaveformError: React.FunctionComponent<{error: string | Error}> = (props) => {
    const error = props.error;
    if (!error) {
        return null;
    }

    const getUserFacingErrorMessaging = (error: string | Error): { title: string, msg: JSX.Element } => {
        const err = (error as Error).message ? (error as Error).message : (error as string);

        switch (err) {
            // TODO: These are the distinct error cases we hope to be able to handle and provide relevant messaging for.
            // The error codes are TBD for now as Preview mode is not sufficiently developed for these to have been established.
            case 'breaks':
                return {
                    msg: <Fragment>
                            <p>We're having problems adding these breaks to your file right now.</p>
                            <p>Please visit <a href='https://digitalsupport.npr.org'>digitalsupport.npr.org</a>.</p>
                        </Fragment>,
                    title: 'Unable to download file with sponsorship',

                };
            default:
                return {
                    msg: <Fragment>
                            <p>We're having problems performing this action at this time.</p>
                            <p>Please try again. If you still have problems, visit <a
                                href='https://digitalsupport.npr.org'>digitalsupport.npr.org</a>.
                            </p>
                        </Fragment>,
                    title: 'Unable to load audio file',

                };
        }
    };

    const { msg, title } = getUserFacingErrorMessaging(error);

    return (
        <div className='waveform-error'>
            <h2>{title}</h2>
            {msg}
        </div>
    );

};

