import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducer';
import { isDmrSingle, isDmrInterval } from '../interfaces/TimelineEvent';

/**
 * Displays whichever detail editor, if any, is appropriate for the active event.
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const EventDetail: React.FunctionComponent = () => {
    const activeEventId = useSelector((state: RootState) => state.editor.activeEventId);
    const activeEvent = useSelector((state: RootState) => {
        return state.editor.timelineEvents.find(event => event.id === activeEventId);
    });

    const getDetailComponent = (): JSX.Element | null => {
        // Presently the app deals only with DMR events, and DMR events presently do not require a detail editor, as they can be fully
        // edited inline.
        if (isDmrSingle(activeEvent) || isDmrInterval(activeEvent)) {
            return null;
        }

        // TODO: Add logic to select which component to display: IntervalEditor, InsertEditor, EventEditor, or some as-yet unwritten one.
    };

    return activeEventId && getDetailComponent();
};
