import React from 'react';
import Labels from '../assets/labels.json';
import { useDispatch, useSelector } from 'react-redux';
import { newBreakAdded, EditorStageStates } from '../store/editor/editorSlice';
import './DmrEvents.css';
import { DmrEventsTable } from './DmrEventsTable';
import { RootState } from '../store/reducer';

/**
 * Displays the section title and the DMR events table.
 *
 * Usage:
 * ```js
 * <DmrEvents />
 * ```
 */
// tslint:disable-next-line: variable-name - React function components must be capitalized
export const DmrEvents: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    // The use of a selector is favored over a prop because state changes in the parent component would
    // cause essentially the whole app to need re-rendering.
    const isLocked = useSelector((state: RootState) => state.editor.stageState === EditorStageStates.locked);

    const addBreak = () => {
        dispatch(newBreakAdded());
    };

    const renderClassNames = (): string => {
        const classNames = ['events-dmr', 'card'];

        if (isLocked) {
            classNames.push('locked');
        }

        return classNames.join(' ');
    };

    return (
        <section className={renderClassNames()}>
            {isLocked && <div className='overlay'></div>}
            <header>
                {Labels.eventsTitle}
                <button className='btn-medium primary add-break'
                    disabled={isLocked}
                    onClick={addBreak}>
                    {Labels.addBreakButton}
                </button>
            </header>
            <section>
                {<DmrEventsTable />}
            </section>
        </section>
    );
};
