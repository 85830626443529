import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '..';
import { getConfig, AppConfig } from '../../utils/config';
import { createOrExtendSession, isApiSuccess } from '../../api/atmServer';
import { setSessionId } from '../session/actions';
import { fetchSessionIdSucceeded, fetchSessionIdFailed, GlobalMessage } from '../editor/editorSlice';
import Labels from '../../assets/labels.json';

export type ConfigState = AppConfig | null;

const initialState: ConfigState = null;

const slice = createSlice({
    initialState,
    name: 'config',
    reducers: {
        appConfigRetrieved(state, action: PayloadAction<AppConfig>) {
            return action.payload;
        },
    },
});

export const {
    appConfigRetrieved,
} = slice.actions;

// begin thunks
export const fetchClientConfig = (): AppThunk => async (dispatch) => {
    try {
        const appConfig = await getConfig();
        dispatch(appConfigRetrieved(appConfig));
    } catch (e) {
        // TBD
    }
};

export const fetchSessionId = (accessToken: string): AppThunk => async (dispatch) => {
    const result = await createOrExtendSession(null, accessToken);

    if (isApiSuccess(result)) {
        dispatch(setSessionId(result.payload.data));
        dispatch(fetchSessionIdSucceeded());
    } else {
        let message: GlobalMessage;

        if (result.payload === 'unauthorized') {
            message = {
                type: 'error',
                content: Labels.loadSourceAudio.error.unauthorized,
            };
        } else {
            message = {
                type: 'error',
                content: Labels.loadSourceAudio.error.unknown,
            };
        }
        dispatch(fetchSessionIdFailed(message));
    }
};

export const configReducer = slice.reducer;
