import React from 'react';

export interface WaveformApi {
    getDuration: () => string;
    getPlayheadPosition: () => string;
}

export const waveformContext = React.createContext<WaveformApi>(null);

// tslint:disable-next-line: variable-name - The provider will be used like a React component, and components must be capitalized
export const WaveformProvider = waveformContext.Provider;
