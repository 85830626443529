import React from 'react';
import PropTypes from 'prop-types';
import './TextInput.css';

/**
 * Renders a text input
 *
 * Usage:
 * ```js
 * <TextInput />
 * ```
 */
const TextInput = (props) => (
    <label className={props.isValid && !props.isValid() ? props.className + ' invalid' : props.className}>{props.label || ''}
        <input type="text"
            data-for={props.id}
            data-tip
            className='ti'
            autoFocus={props.autoFocus}
            name={props.name}
            maxLength={props.maxlength}
            value={props.text}
            onChange={props.onTextChange}
            placeholder= {props.placeholder}
        />

    </label>
);


TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    isValid: PropTypes.func,
    autoFocus: PropTypes.bool,
    name: PropTypes.string,
    maxlength: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onTextChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
}

export default TextInput;
