import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { isDmrInterval } from '../../interfaces/TimelineEvent';
import { publishDateUpdated } from '../../store/editor/editorSlice';
import { RootState } from '../../store/reducer';
import { sub } from 'date-fns';
import './DateDisagreementModal.css';
import { formatInTimeZone } from 'date-fns-tz';

// tslint:disable-next-line: variable-name - React function components must be capitalized
export const DateDisagreementModal: React.FunctionComponent = () => {
    const bakedInDurationDays = useSelector((state: RootState) => state.config.bakedInDurationDays);
    const userPublishTimestamp = useSelector((state: RootState) => state.editor.publishDate);

    // Note: This is null if no effective date is extracted OR if more than one is. More than
    // one effective date in a file would be an abnormal condition (the UI does not support it).
    // Since it is unclear what action we would ask the user to take in such a case, we will
    // treat it as if the file lacked effective date data altogether and accept the user's
    // submission without quesiton.
    const fileEffectiveTimestamp = useSelector((state: RootState) => {
        const { timelineEvents } = state.editor;
        const effectiveTimestamps = timelineEvents
            .map((event) => isDmrInterval(event) ? event.effectiveDate : null)
            .filter((event) => event !== null );
        const dedupedEffectiveTimestamps = [... new Set(effectiveTimestamps)];

        return dedupedEffectiveTimestamps.length === 1 ? dedupedEffectiveTimestamps[0] : null;
    });

    const getFilePublishTimestamp = (): number | null => {
        if (fileEffectiveTimestamp === null) {
            return null;
        }
        
        const effectiveDate = new Date(fileEffectiveTimestamp * 1000);
        const calculatedPublishDate = sub(effectiveDate, { days: bakedInDurationDays });

        return Math.round(calculatedPublishDate.getTime() / 1000);
    } 

    const filePublishTimestamp = getFilePublishTimestamp() ;

    function formatDate(unixTimestamp: number): string {
        return (
            unixTimestamp === null
                ? ''
                : formatInTimeZone(unixTimestamp * 1000, 'America/New_York', 'M/dd/Y')
            );
    }

    function isModalOpen(): boolean {
        return (
            // ensure the user has set the pub date
            typeof userPublishTimestamp === 'number' &&
            // ensure the date has been extracted from the audio file
            typeof filePublishTimestamp === 'number' &&
            // modal is only relevant in the case of a discrepancy
            userPublishTimestamp !== filePublishTimestamp
        );
    }

    const dispatch = useDispatch();
    function dispatchPubDate(timestamp: number): void {
        dispatch(publishDateUpdated(timestamp));
    }

    Modal.setAppElement('#root');
    return (
        <Modal
            isOpen={isModalOpen()}
            className='modal-centered modal-date-disagreement'
            overlayClassName='overlay'>
            <h2>Differing Publication Dates</h2>
            <p>This file was previously tagged with a different publication date than the one you provided. This date informs when baked-in sponsorship are automatically converted to dynamic sponsorship.</p>
            <p>Which date would you like to use?</p>
            <div className='button-group'>
                <div className='option-container date-file mx-10'>
                    <div className='h6'>Original Date</div>
                    <div className='date-formatted'>{formatDate(filePublishTimestamp)}</div>
                    <button className='btn-large primary' onClick={() => { dispatchPubDate(filePublishTimestamp) }}>
                        Use Original
                    </button>
                </div>
                <div className='option-container date-user mx-10'>
                    <div className='h6'>Updated Date</div>
                    <div className='date-formatted'>{formatDate(userPublishTimestamp)}</div>
                    <button className='btn-large primary' onClick={() => { dispatchPubDate(userPublishTimestamp) }}>
                        Use Updated
                    </button>
                </div>
            </div>
        </Modal>
    );
}
